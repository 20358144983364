<template>
  <v-card>
    <v-data-table :items="items" :headers="headers" :hide-default-footer="true" :header-props="{ class: 'bg-surface'}">
      <template v-slot:item="{ item }">
        <tr :class="`bg-${rowClass(item.bpCriteria)}`">
          <td>{{ item.bpCriteria }}</td>
          <td>{{ item.sysTolicRange }}</td>
          <td>{{ item.DiastolicRange }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script lang="ts" setup>
import { defineProps, onMounted, watch, PropType, ref, computed } from 'vue';
import { IVitalConfigThresholds } from '@/interfaces/IVitalConfigure';

const props = defineProps({
  systolicValuesRange: {
    type: Object as PropType<IVitalConfigThresholds>,
    required: true,
  },
  diastolicValuesRange: {
    type: Object as PropType<IVitalConfigThresholds>,
    required: true,
  },
});

const sysVitalRange = ref<IVitalConfigThresholds>();
const diaVitalRange = ref<IVitalConfigThresholds>();

const headers = [
  { title: 'Blood Pressure Category', value: 'bpCriteria' },
  { title: 'Systolic mmHg', value: 'sysTolicRange' },
  {
    title: 'Diastolic mmHg',
    value: 'DiastolicRange',
  },
]

watch(() => props.systolicValuesRange, (newValue: IVitalConfigThresholds) => {
  sysVitalRange.value = newValue;
})

watch(() => props.diastolicValuesRange, (newValue: IVitalConfigThresholds) => {
  diaVitalRange.value = newValue;
});

const items = computed(() => {

  return[
  {
    bpCriteria: 'Normal',
    sysTolicRange: sysVitalRange.value?.normal,
    DiastolicRange: diaVitalRange.value?.normal,
  },
  {
    bpCriteria: 'Warning',
    sysTolicRange: `${sysVitalRange.value?.warningMin},   ${sysVitalRange.value?.warningMax}`,
    DiastolicRange: `${diaVitalRange.value?.warningMin},  ${diaVitalRange.value?.warningMax}`,
  },
  {
    bpCriteria: 'Critical',
    sysTolicRange: `${sysVitalRange.value?.criticalMin},   ${sysVitalRange.value?.criticalMax}`,
    DiastolicRange: `${diaVitalRange.value?.criticalMin},  ${diaVitalRange.value?.criticalMax}`,
  },
  ]
})

const rowClass = (bpCriteria: string) => {
  switch (bpCriteria) {
    case 'Normal':
      return 'success';
    case 'Warning':
      return 'warning';
    case 'Critical':
      return 'error';
  }
};

</script>
<style scoped>
</style>

export const MeasurementUnits = [
  {
    title: "% in range",
    value: "PERC_IN_RANGE",
  },
  {
    title: "Count in range",
    value: "COUNT_IN",
  },
  {
    title: "Count above range",
    value: "COUNT_LAST",
  },
];

export enum VITAL_CONFIGURATION {
  SYSTOLIC = "Systolic",
  DIASTOLIC = "Diastolic",
  IHB = "Irregular heart beat",
  REPORTING_FREQUENCY = "Reporting frequency",
}

export enum VITAL_CONFIGURATION_CRITERIA {
  BP = "Blood Pressure",
  PULSE = "PULSE"
}

export enum VITAL_MEASUREMENT_RULE_UNIT {
  PERCENTAGE_IN_RANGE="PERCENTAGE_IN_RANGE",
  COUNT_IN_RANGE="COUNT_IN_RANGE",
  CONSECUTIVE_COUNT_IN_RANGE="CONSECUTIVE_COUNT_IN_RANGE"
}

export enum BP_UNIT {
  MMHG = 'mmhg'
}

<template>
  <v-card class="px-4 w-100" width="auto" flat>
    <v-skeleton-loader v-if="inventoryLoading || loading" class="mx-auto" type="table" color="surface" />
    <v-data-table v-else :headers="columns" :items="inventoryItemData">
      <template v-slot:[`item.patientName`]="{ item }">
        <v-btn v-if="isEmpty(item.patientName) && item.active" dense class="text-none" density="compact" rounded="false"
          variant="tonal" @click="openAssociateForm(item)">
          Assign
        </v-btn>
        <template v-else-if="!isEmpty(item.patientName)">
          <span @click="onPatientRowSelection(item)" class="link-style cursor-pointer">{{ item.patientName }}</span>
        </template>
        <template v-else>
          <p>N/A</p>
        </template>

      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="action-buttons">
          <v-btn v-if="item.patientName" dense class="text-none" size="small" rounded="false" variant="outlined"
            width="96" @click="showConfirmMessage(item, item.patientName)">
            Unassign
          </v-btn>
          <v-btn v-else-if="!item.active" dense class="text-none px-7" size="small" rounded="false" variant="elevated"
            width="96" @click="showConfirmMessage(item)">
            Enable
          </v-btn>
          <v-btn v-else-if="item.active" dense class="text-none px-6" size="small" rounded="false" variant="tonal"
            width="96" @click="showConfirmMessage(item)">
            Disable
          </v-btn>
          <v-btn dense class="text-none mx-2" size="small" @click="$emit('editDevice', { shortCode, item })" rounded
            variant="tonal" icon="mdi-pencil" />
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" width="auto">
      <InventoryPatientAssociation @cancel-assignation="closeAssociateForm" :selected-patient-input="patientInput"
        :program-subscribed-data-loader="patientDetailsDependencies.getPatientBillingConfig"
        @assign-device="assignInventoryItem" :device-type="deviceType" :inventory-meta-data="inventoryMetaData"
        :short-code="shortCode" @get-patient-detail="getPatientDetails" />
    </v-dialog>
    <v-dialog v-model="confirmDialog" persistent width="500px">
      <v-card min-height="200px">
        <v-card-title class="font-weight-bold bg-surface text-onSurfaceVar pl-5">Confirm</v-card-title>
        <v-card-text v-if="patientId" class="text-h6">
          Are you sure you want to unassign <v-label class="text-h6 font-weight-bold text-primary-darken-1"
            color="primary">{{ deviceName }}</v-label> from <v-label color="primary"
            class="text-h6 font-weight-bold text-primary-darken-1">{{ patientName }}</v-label>?
        </v-card-text>
        <v-card-text v-else-if="active" class="text-h6">
          Are you sure you want to disable <v-label class="text-h6 font-weight-bold text-primary-darken-1"
            color="primary">{{ deviceName }}</v-label>?
        </v-card-text>
        <v-card-text v-else-if="!active" class="text-h6">
          Are you sure you want to enable <v-label class="text-h6 font-weight-bold text-primary-darken-1">{{
            deviceName
            }}</v-label> ?
        </v-card-text>
        <v-card-actions class="px-3 py-5">
          <v-row justify="end">
            <v-col sm="12" md="3" class="text-center">
              <v-btn color="primary" class="text-white" size="small" rounded="false" @click="confirmDialog = false"
                variant="tonal" elevation="3" block>
                Cancel
              </v-btn>
            </v-col>
            <v-col sm="12" md="3" class="text-center">
              <v-btn color="primary" class="text-white" size="small" variant="elevated" type="submit" rounded="false"
                v-if="patientId" block elevation="3" @click="unAssignDevice">
                Unassign
              </v-btn>
              <v-btn color="primary" class="text-white" variant="elevated" type="submit" rounded="false"
                v-else-if="active" size="small" elevation="3" block @click="removeItemFromInventory">
                Disable
              </v-btn>
              <v-btn color="primary" class="text-white" variant="elevated" type="submit" rounded="false"
                v-else-if="!active" size="small" block @click="enableInventoryItem">
                Enable
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar color="success" v-model="showSuccessMessage" class="mt-16" location="top right">{{ successMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar color="error" v-model="showErrorMessage" class="mt-16" location="top right">{{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>

    <v-dialog v-model="showConfirmationPopup" persistent max-width="600px">
      <v-card min-height="200px">
        <v-card-title class="font-weight-bold bg-surface text-onSurfaceVar pl-5">Confirmation</v-card-title>
        <v-card-text class="text-h6">
          {{ templateParams.patientName }} is enrolled to RPM. Do you want to obtain their consent now to complete
          subscription?
        </v-card-text>
        <v-card-actions class="px-4 py-5">
          <v-row justify="end">
            <v-col sm="12" md="2" class="text-center">
              <v-btn color="primary" class="text-white" density="compact" variant="tonal" elevation="3" rounded="false"
                @click="showConfirmationPopup = false">
                No
              </v-btn>
            </v-col>
            <v-col sm="12" md="2" class="text-center"
              @click="() => { showTemplate = true, showConfirmationPopup = false }">
              <v-btn color="primary" class="text-white" variant="flat" type="submit" density="compact" elevation="3"
                rounded="false">
                Yes
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>

  <v-dialog fullscreen class="template-viewer-dialog" persistent v-model="showTemplate">
    <e-consent-template-viewer @close-template-viewer="showTemplate = false" :patient-profile="patientProfile"
      :econsent-template-id="programSubscribedData.eConsentTemplateId" :template-params="templateParams"
      :get-consent-upload-url="patientDetailsDependencies.getProgramEConsentUploadUrl"
      @reload-patient-program-consents="handleConsentSigned()"
      :sign-patient-program-consent="patientDetailsDependencies.signPatientProgramConsent"
      :selected-program-id="programSubscribedData.eConsentTemplateId"
      :data-loader="patientDetailsDependencies.getProgramEConsentTemplate"></e-consent-template-viewer>
  </v-dialog>

  <v-dialog fullscreen v-model="showPatientMonitorDialog">
    <patient-monitor :compact-view="true" :patient-profile="selectedPatientProfileProps"
      :get-dependencies="getPatientMonitorDependencies" @request-patient-monitor-exit="handlePatientMonitorExit"
      :full-screen-mode="true" />
  </v-dialog>

</template>

<script setup lang="ts">
import { PropType, ref, watch, onMounted, reactive } from 'vue';
import InventoryPatientAssociation from "./InventoryPatientAssociation.vue";
import { assign, get, includes, isEmpty, map, pick, first } from "lodash";
import { IAssignPatientInput, IInventoryDeviceInput, IInventoryItemData, IQardioMeta, ITenoviMeta } from "@/interfaces/InventoryItem.interface";
import { IRequestorInput } from '@/interfaces/task.interface';
import { formatDatestamp } from '@/composables/FormUtility';
import { IPatientData } from '@/interfaces/Service.interface';
import { TemplateParams } from '@/interfaces/econsent-template.interface';
import moment from 'moment';
import { IPatientProgram } from '@/interfaces/econsent.interface';
import EConsentTemplateViewer from '../econsent/EConsentTemplateViewer.vue';
import { IPatientInfo, IPatientProfileProps } from '../../interfaces/patient.interface';
import PatientMonitor from "@/components/patient/profile/PatientMonitor.vue";
import { getPatientMonitorDependencies } from "@/services/PatientMonitor.service";
import { Program } from '@/enums/patientInsights.enum';

const columns = [
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Type",
    key: "type.label",
  },
  {
    title: "Patient Name",
    key: "patientName",
  },
  {
    title: 'Associated On',
    key: 'associationOn'
  },
  {
    title: 'Shipping Status',
    key: 'shippingStatus',
  },
  {
    title: "Status",
    key: "status",
  },
  {
    title: "Action",
    key: "action",
  },
];
const patientId = ref("");
const loading = ref(false);
const showSuccessMessage = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref('')
const successMessage = ref('');
const itemId = ref('');
const active = ref(false);
const deviceName = ref('');
const patientName = ref(null as null | string);
const defaultPatientProfileProps: IPatientProfileProps = {
  patientId: "",
  patientInfoLoader: getPatientMonitorDependencies.getPatientByPatientId,
};
const showPatientMonitorDialog = ref(false);
const selectedPatientProfileProps = ref<IPatientProfileProps>(defaultPatientProfileProps);


const props = defineProps({
  inventoryItems: {
    type: Array as () => IInventoryItemData[],
    required: true,
  },
  unAssignDeviceHandler: {
    type: Function as PropType<(itemId: string) => Promise<IInventoryItemData>>,
    required: true,
  },
  patientInput: {
    type: Object as PropType<IRequestorInput>,
    required: true,
  },
  assignInventoryDevice: {
    type: Function as PropType<(itemId: string, input: IAssignPatientInput) => Promise<IInventoryDeviceInput>>,
    required: true,
  },
  removeItemFromInventory: {
    type: Function as PropType<(itemId: string) => Promise<boolean>>,
    required: true,
  },
  enableInventoryItem: {
    type: Function as PropType<(itemId: string) => Promise<boolean>>,
    required: true,
  },
  shortCode: {
    type: String,
    required: false,
  },
  patientDetailsDependencies: {
    type: Object as PropType<IPatientData>,
    required: true,
  },
  inventoryLoading: {
    type: Boolean,
    default: true,
  }
});

const { patientDetailsDependencies } = props;

const inventoryItemData = ref([] as IInventoryItemData[]);
const externalItemId = ref('')
const dialog = ref(false);
const confirmDialog = ref(false);
const inventoryMetaData = ref({} as ITenoviMeta | IQardioMeta);
const deviceType = ref('');
const showConfirmationPopup = ref(false);

const programSubscribedData = reactive({
  isProgramSubscribed: false,
  programStatus: '',
  eConsentTemplateId: '',
  programId: '',
});

const patientProfile = reactive({
  patientId: '',
  patientInfo: {} as IPatientInfo,
})


const patientPrograms = ref([] as IPatientProgram[]);

const templateParams = ref({
  patientName: '',
  date: moment().format('MM/DD/YYYY')
} as TemplateParams);

const showTemplate = ref(false);

const emit = defineEmits(['getInventoryItems', 'editDevice'])

const openAssociateForm = (item: IInventoryItemData) => {
  inventoryMetaData.value = item.meta;
  deviceType.value = item.type.type;
  externalItemId.value = item.id;
  dialog.value = true;
};

const closeAssociateForm = () => {
  dialog.value = false;
};

const showConfirmMessage = (item: IInventoryItemData, selectedPatientName?: string) => {
  if (selectedPatientName) {
    patientName.value = selectedPatientName;
  }
  patientId.value = get(item, 'association.patient.id', '');
  itemId.value = item.id;
  active.value = item.active;
  deviceName.value = item.name;
  confirmDialog.value = true;
};

const getInventoryItemDetails = (inventoryItems: IInventoryItemData[]) => {
  inventoryItemData.value = map(inventoryItems, (item) => {
    const patientName = item.association ? item.association.patient.name : null;
    const associationOn = item.association && item.association.associatedOn ? item.association.associatedOn : null;
    return {
      ...item,
      associationOn: formatDatestamp(associationOn as string),
      patientName,
      status: item.active ? 'ACTIVE' : 'INACTIVE',
      shippingStatus: item.shippingStatus || 'N/A',
    };
  }) as IInventoryItemData[];
};

const unAssignDevice = async () => {
  try {
    confirmDialog.value = false;
    loading.value = true;
    await props.unAssignDeviceHandler(itemId.value);
    loading.value = false;
    successMessage.value = 'Device unassigned successfully';
    showSuccessMessage.value = true;
    emit('getInventoryItems');
  } catch (err) {
    loading.value = false;
    confirmDialog.value = false;
    const error = err as Error;
    errorMessage.value = error.message;
    showErrorMessage.value = true;
  }
};

const assignInventoryItem = async (patientInput: IAssignPatientInput) => {
  try {
    dialog.value = false;
    loading.value = true;
    const itemId = externalItemId.value;
    await props.assignInventoryDevice(itemId, patientInput);

    const { isProgramSubscribed, programStatus, programId } = programSubscribedData;

    if (!isProgramSubscribed && (includes(['PENDING', 'SIGNED'], programStatus) || isEmpty(programStatus))) {
       await patientDetailsDependencies.subscribePrograms(patientProfile.patientId, [programId])
      if (isEmpty(programStatus)) {
        await loadPatientPrograms(patientInput.patientId);
      }
    }

    loading.value = false;
    showProgramConsentSignedConfirmation();

    emit('getInventoryItems');
    successMessage.value = 'Device was assigned successfully'
    showSuccessMessage.value = true;
  }
  catch (error) {
    const err = error as Error;
    loading.value = false;
    errorMessage.value = err.message;
    showErrorMessage.value = true;
  }
}

const getPatientDetails = async (patientId: string) => {
  loadPatientPrograms(patientId)
  const patientData = await patientDetailsDependencies.getPatientByPatientId([patientId]);
  patientProfile.patientId = patientId;
  patientProfile.patientInfo = patientData;
  templateParams.value.patientName = `${patientData?.firstName} ${patientData?.lastName}`
}

const removeItemFromInventory = async () => {
  try {
    confirmDialog.value = false
    loading.value = true;
    await props.removeItemFromInventory(itemId.value);
    loading.value = false;
    successMessage.value = 'Device disabled successfully'
    showSuccessMessage.value = true;
    emit('getInventoryItems');
  }
  catch (error) {
    loading.value = false;
    confirmDialog.value = false;
    const err = error as Error;
    errorMessage.value = err.message;
    showErrorMessage.value = true;
  }
}

const enableInventoryItem = async () => {
  try {
    confirmDialog.value = false
    loading.value = true;
    await props.enableInventoryItem(itemId.value);
    loading.value = false;
    successMessage.value = 'Device enabled successfully'
    showSuccessMessage.value = true;
    emit('getInventoryItems');
  }
  catch (error) {
    loading.value = false;
    confirmDialog.value = false;
    const err = error as Error;
    errorMessage.value = err.message;
    showErrorMessage.value = true;
  }
}

watch(() => props.inventoryItems, (newVal) => {
  if (newVal) {
    getInventoryItemDetails(newVal)
  }
}, {deep: true})

const loadPatientPrograms = async (patientId: string) => {
  const patientProgramConsents = await patientDetailsDependencies.patientProgramConsents(patientId);

  const rpmProgram = first(patientProgramConsents.filter(({ shortCode }) => shortCode === Program.RPM));
  if (!rpmProgram) return;

  patientPrograms.value = map(patientProgramConsents, consent =>
    assign(
      pick(consent, ['isSubscribed', 'programId', 'programName', 'isDefault', 'parentProgramId', 'patientId']),
      {
        patientProgramId: consent.parentProgramId,
        consent: consent.consent && pick(consent.consent, [
          'id', 'signedAt', 'status', 'consentDownloadUrl', 'eConsentTemplateId'
        ]),
      }
    ) as IPatientProgram
  );

  const { isSubscribed, consent, programId } = 
    patientPrograms.value.find(({ programId }) => programId === rpmProgram.programId) || {};

  assign(programSubscribedData, {
    isProgramSubscribed: isSubscribed,
    programStatus: consent?.status || '',
    eConsentTemplateId: consent?.eConsentTemplateId || '',
    programId,
  });
};

const showProgramConsentSignedConfirmation = () => {
  const { isProgramSubscribed, programStatus } = programSubscribedData;
  if (isProgramSubscribed == null && programStatus === 'PENDING') {
    showConfirmationPopup.value = true;
  }
  else if (isProgramSubscribed === true && programStatus === 'PENDING') {
    showConfirmationPopup.value = true;
  }
  else {
    showConfirmationPopup.value = false;
  }
}


const handleConsentSigned = () => {
  showTemplate.value = false;
}

const onPatientRowSelection = (item: IInventoryItemData) => {
  selectedPatientProfileProps.value = {
    ...selectedPatientProfileProps.value,
    patientId: item.association?.patientId as string,
  };
  showPatientMonitorDialog.value = true;
};

const handlePatientMonitorExit = () => {
  selectedPatientProfileProps.value = defaultPatientProfileProps;
  showPatientMonitorDialog.value = false;
};

</script>
<style scoped>
.action-buttons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
</style>

import gql from "graphql-tag";

export const GET_PATIENT_PROGRAM_BILLING_CYCLE = gql`
  query getPatientProgramBillingCycle($patientId: UUID!, $filters:BillingFilter ) {
    patientProgramBillingCycle(patientId: $patientId,filters: $filters) {
      id
      patientId
      programsInCycle {
        id
        patientId
        patientProgramId
        cycleStart
        cycleDays
        isCurrentCycle
        cycleType
        cycleStatus {
          daysActive
          daysMissed
          daysRemaining
          activityHistory {
            date
            activity {
              vital
              dataPointId
            }
          }
        }
        program
      }
      programsSelectedForBilling {
        id
        patientId
        patientProgramId
        cycleStart
        cycleDays
        isCurrentCycle
        cycleType
        cycleStatus {
          daysActive
          daysMissed
          daysRemaining
          activityHistory {
            date
            activity {
              vital
              dataPointId
            }
          }
        }
        program
      }
      billingDistributions {
        cptCode {
          code
          bucket
        }
        ccmBucket {
          timeInCycle
          billingCount
          revenue
          billable
        }
        cccmBucket {
          timeInCycle
          billingCount
          revenue
          billable
        }
        pcmBucket {
          timeInCycle
          billingCount
          revenue
          billable
        }
        rpmBucket {
          timeInCycle
          billingCount
          revenue
          billable
        }
      }
      firstName
      lastName
      dob
      isActive
      isDeceased
      provider {
        userId
        orgId
        firstName
        lastName
        mobilePhone
        email
        npi
        active
        isPrimaryProvider
      }
      createdTimestamp
      additionalBillingInfo
      isCustomized
    }
  }
`;

export const UPDATE_PATIENT_PROGRAM_BILLING_CYCLE = gql`
  mutation updatePatientProgramBillingCycle(
    $input: PatientProgramBillingInput!
  ) {
    savePatientProgramBillingCycle(input: $input) {
      patientId
      programsInCycle {
        id
        patientId
        patientProgramId
        cycleStart
        cycleDays
        isCurrentCycle
        cycleType
        cycleStatus {
          daysActive
          daysMissed
          daysRemaining
          activityHistory {
            date
            activity {
              vital
              dataPointId
            }
          }
        }
        program
      }
      programsSelectedForBilling {
        id
        patientId
        patientProgramId
        cycleStart
        cycleDays
        isCurrentCycle
        cycleType
        cycleStatus {
          daysActive
          daysMissed
          daysRemaining
          activityHistory {
            date
            activity {
              vital
              dataPointId
            }
          }
        }
        program
      }
      billingDistributions {
        cptCode {
          code
          bucket
        }
        ccmBucket {
          timeInCycle
          billingCount
          revenue
          billable
        }
        cccmBucket {
          timeInCycle
          billingCount
          revenue
          billable
        }
        pcmBucket {
          timeInCycle
          billingCount
          revenue
          billable
        }
        rpmBucket {
          timeInCycle
          billingCount
          revenue
          billable
        }
      }
      firstName
      lastName
      dob
      isActive
      isDeceased
      provider {
        userId
        orgId
        firstName
        lastName
        mobilePhone
        email
        npi
        active
        isPrimaryProvider
      }
      createdTimestamp
      additionalBillingInfo
    }
  }
`;

export const GET_EMULATED_BILLING = gql`
  query emulateOrgBillingWithCustomSettings(
    $cycle: CycleInput!
    $billingCustomizationInput: BillingCustomizationInput!
  ) {
    emulateOrgBillingWithCustomSettings(
      cycle: $cycle
      billingCustomizationInput: $billingCustomizationInput
    ) {
      patientId
      billingDistributions {
        cptCode {
          code
          bucket
        }
        ccmBucket {
          timeInCycle
          billingCount
          revenue
          revenue
          billable
        }
        cccmBucket {
          timeInCycle
          billingCount
          revenue
          revenue
          billable
        }
        pcmBucket {
          timeInCycle
          billingCount
          revenue
          revenue
          billable
        }
        rpmBucket {
          timeInCycle
          billingCount
          revenue
          revenue
          billable
        }
      }
      firstName
      lastName
      additionalBillingInfo
    }
  }
`;

export const PERSIST_EMULATED_BILLING = gql`
  mutation persistEmulatedOrgBilling(
    $cycle: CycleInput!
    $billingCustomizationInput: BillingCustomizationInput!
    $isAcknowledged: Boolean
  ) {
    persistEmulatedOrgBilling(
      cycle: $cycle
      billingCustomizationInput: $billingCustomizationInput
      isAcknowledged: $isAcknowledged
    )
  }
`;

export const GET_BILLING_CONFIGURATION = gql`
  query getCurrentBillingConfiguration($cycle: CycleInput!) {
    getCurrentConfiguration(cycle: $cycle) {
      id
      patientId
      orgId
      configuration {
        billingStrategy
      }
      cycleStart
      cycleDays
      cycleType
    }
  }
`;

export const CREATE_CUSTOMIZED_BILLING_DISTRIBUTION = gql`
 mutation upsertPatientCustomDistribution($customCPTDistributionInput: CustomCPTDistributionInput!){
  upsertPatientCustomDistribution(customCPTDistributionInput: $customCPTDistributionInput)
 }
 `;

 export const PROGRAM_BILLING_CONFIGURATION = gql`
{
    getAllProgramsConfiguration{
      id
    orgId
    shortCode
    label
    parentProgramId
    isDefault
    codes {
      code
      bucket
      unitSlabMins
      unitRate
      maxCount
      primaryProcedureCode
      description
      billingConfig {
        billableResource
        cutOff
      }
    }
    }
  }
 `

import gql from "graphql-tag";

export const EXPORT_SNAPSHOT_PATIENT_INFO = gql`
  query exportSnapshotPatientInfo(
    $patientId: String!
    $patientSnapshotFilter: PatientSnapshotFilter!
  ) {
    exportSnapshotPatientInfo(
      patientId: $patientId
      patientSnapshotFilter: $patientSnapshotFilter
    ) {
      patient {
        patientId
        firstName
        lastName
        middleName
        dob
        gender
        email
        mobilePhone
        addressLine1
        addressLine2
        city
        state
        zip
        isActive
        isDeceased
        mrn
        orgId
        providers {
          userId
          isPrimaryProvider
          firstName
          lastName
          orgId
          orgUserId
          mobilePhone
          email
          npi
          active
        }
        createdTimestamp
        externalIds {
          key
          value
          serviceProviderId
          label
        }
        lastCall
        lastAppointment
      }
      patientNote {
        orgId
        mpi
        mrn
        note {
          noteId
          bucketId
          createdAt
          lastModifiedAt
          author {
            firstName
            lastName
            email
            userId
          }
          tags
          noteContent {
            title
            message
            source
          }
        }
      }
      timeLog {
        count
        totalTimeSpent
        results {
          timeLogId
          activity {
            activityId
            orgId
            label
            description
            isActive
            isDefault
            variableDefs {
              key
              required
              parseAs
            }
          }
          startTime
          endTime
          timeSpent
          isAutoLogged
          loggedBy {
            orgUserId
            userId
            firstName
            lastName
            emailAddress
          }
          note {
            orgId
            mpi
            mrn
            note {
              noteId
              bucketId
              createdAt
              lastModifiedAt
              author {
                firstName
                lastName
                email
                userId
              }
              tags
              noteContent {
                title
                message
                source
              }
            }
          }
        }
      }
      patientVitalReading {
        patientReadings {
          dataSourceRef {
            patientId
            sourceDefinitionId
            sourceId
            vital
          }
          dataPoints {
            collectedTimestamp
            collectedTimeZone
            collectedBy
            reading {
              ... on BPReading {
                diastolic
                rawData
                systolic
                heartRate
                ihb
              }
            }
            firstBpMeasurement
          }
        }
        count
      }
    }
  }
`;

import { LookBackRange } from "@/enums/readings";
import {
  GET_PATIENT_DATA_SOURCES,
  GET_PATIENT_READINGS,
} from "@/graphql/resources/PatientRepository";
import {
  ILookBackRangeItem,
  IPatientDataSourceItem,
  PatientBPReading,
  PatientDataSourceExtended,
} from "@/interfaces/patient.interface";
import { apolloClient } from "@/plugins/graphqlclient";

export const getPatientDataSources = async (
  patientId: string
): Promise<IPatientDataSourceItem[]> => {
  let sourceList = [] as IPatientDataSourceItem[];
  await apolloClient
    .query({
      query: GET_PATIENT_DATA_SOURCES,
      variables: {
        patientId: patientId,
      },
    })
    .then((data) => {
      const patientDataSources = data.data.patientDataSources;
      sourceList = patientDataSources.map(
        (patientDataSource: PatientDataSourceExtended) => {
          const {
            dataSource: { sourceId, sourceName },
          } = patientDataSource;
          return {
            title: sourceName,
            label: sourceName,
            value: sourceId,
          };
        }
      );
    })
    .catch((_error) => {
      throw _error;
    });
  return sourceList;
};

export const getPatientReadings = async (
  patientId: string,
  lookBackRange: string,
  sourceId?: string
) => {
  const patientReadings = [] as PatientBPReading[];
  const filter = {} as { lookBackRange: string; sourceId?: string };
  if (lookBackRange) {
    filter.lookBackRange = lookBackRange;
  }
  if (sourceId) {
    filter.sourceId = sourceId;
  }
  await apolloClient
    .query({
      query: GET_PATIENT_READINGS,
      variables: {
        patientId: patientId,
        pagination: {},
        filter,
        sort: "collectedTimestamp_DESC",
      },
      fetchPolicy: "network-only",
    })
    .then((data) => {
      if (data?.data?.patientReadings?.patientReadings) {
        patientReadings.push(...data.data.patientReadings.patientReadings);
      }
    });
  return patientReadings;
};

export const getLookBackRanges = async (): Promise<ILookBackRangeItem[]> => {
  return LookBackRange.map((range) => {
    return {
      ...range,
      label: range.title,
    };
  });
};

import { EXPORT_SNAPSHOT_PATIENT_INFO } from "@/graphql/resources/snapshot/PatientSnapshot";
import { IPatientSnapshotFilterInput, IPatientSnapshotPayload } from "@/interfaces/patient.interface";
import { apolloClient } from "@/plugins/graphqlclient";


export const snapshotPatientInfo = async (patientId: string, patientSnapshotFilter: IPatientSnapshotFilterInput): Promise<IPatientSnapshotPayload> => {
  try {
    const result = await apolloClient.query({
      query: EXPORT_SNAPSHOT_PATIENT_INFO,
      variables: {
        patientId,
        patientSnapshotFilter
      },
      fetchPolicy: "network-only",
    });

    return result.data.exportSnapshotPatientInfo;
  } catch (err) {
    return Promise.reject(err);
  }
};

import { IPatientVitalConfigureResponse, IVitalConfigure, PatientVitalsInput, VitalsInput } from "@/interfaces/IVitalConfigure";
import { apolloClient } from "../plugins/graphqlclient";
import {
  CREATE_PATIENT_VITAL,
  CREATE_VITAL,
  GET_PATIENT_VITALS,
  GET_VITALS,
} from "@/graphql/resources/VitalConfiguration";

export const createVital = async (vitalInput: VitalsInput) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_VITAL,
    variables: {
      input: vitalInput,
    },
  });
  return response;
};

export const getVital = async () => {
  const response = await apolloClient.query({
    query: GET_VITALS,
    variables: {
      vital: "BP",
    },
    fetchPolicy: 'network-only'
  });
  return response;
};

export const savePatientVitalsThresholdConfig = async (
  patientVitalInput: PatientVitalsInput
): Promise<IVitalConfigure> => {
  const response = await apolloClient.mutate({
    mutation: CREATE_PATIENT_VITAL,
    variables: {
      input: patientVitalInput,
    },
  });
  return response.data;
};

export const getPatientVitalsThresholdConfig = async (patientId: string):  Promise<IPatientVitalConfigureResponse> => {
  const response = await apolloClient.query({
    query: GET_PATIENT_VITALS,
    variables: {
      patientId: patientId,
      vital: "BP",
    },
    fetchPolicy: 'network-only'
  });
  return response.data;
};

<template>
  <v-card color="surface" variant="outlined" class="w-100 h-100">
    <v-container fluid v-if="showFilter" class="bg-surface">
      <v-row justify="start">
        <v-col cols="10">
          <template v-for="(value, key) in filterData" :key="key">
            <v-chip color="tertiary" class="ma-1" size="small" :disabled="loading" closable rounded
              @click:close="removeFilter(key)" v-if="value !== '' && value !== 0">
              <span class="text-caption">{{ key }}: {{ value }}</span>
            </v-chip>
          </template>
        </v-col>

        <v-col cols="2" class="d-flex align-center justify-center">
          <v-btn size="x-small" rounded variant="tonal" elevation="3" icon="mdi-filter">
            <v-icon icon="mdi-filter" />
            <v-dialog v-model="dialog" transition="dialog-top-transition" activator="parent" width="auto">
              <patient-not-reviewed-filter @not-reviewed-patient="submitNotReviewedPatientFilter"
                @close="dialog = false" />
            </v-dialog>
          </v-btn>
          <v-btn size="x-small" class="mx-4" variant="tonal" elevation="3" @click="clearFilters" v-if="hasFilters"
            rounded icon="mdi-filter-remove" />
        </v-col>
        <v-divider thickness="1" class="mb-2"></v-divider>
      </v-row>

    </v-container>
    <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items-length="totalItems"
      :items="notReviewedPatientLists" :loading="loading" :search="search" item-value="name"
      :items-per-page-options="itemsPerPageOptions" @update:options="paginationChanged" @click:row="onRowClick"
      @update:sort-by="updateSortBy" density="compact">
      <template v-slot:[`item.lastReadingTime`]="{ item }">
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <span v-bind="props">{{ item.lastReadingTime }}</span>
          </template>
          <span>{{ item.lastReadingTooltip }}</span>
        </v-tooltip>
      </template>
    </v-data-table-server>

  </v-card>
</template>
<script setup lang="ts">
import { ISortData, PatientInsightDetail, PatientInsight, PatientProgramVitalsFilter, PatientProgramVitalsSortBy, SeverityPatientFilter } from '@/interfaces/patientInsights.interface';
import { ref, defineProps, watch, computed, onMounted } from 'vue';
import { apolloClient } from '@/plugins/graphqlclient';
import { PATIENTS_NEEDS_REVIEW } from '@/graphql/resources/patientInshights/patientProgramInsightsRepository';
import { PatientProgramVitalsSortKey, SortOrder, Vitals, VitalsSeverity } from '../../../enums/patientInsights.enum';
import PatientNotReviewedFilter from '../filter/PatientNotReviewedFilter.vue';
import { defaultTo, get, map, maxBy } from 'lodash';
import { useEventBus, useTimeAgo } from '@vueuse/core';
import { patientReviewedEventBusKey } from '@/events/bus-keys/patient-reviewd-event.bus-keys';
import { patientProgramInsightsEventBusKey } from '@/events/bus-keys/patient-program-insights-event.bus-key';
import { useQueryClient } from '@tanstack/vue-query';
import moment from 'moment';
import { convertMilliSecondsToMinutes } from '@/helpers/date.helpers';

const props = defineProps({
  patientVitalType: {
    type: String,
    required: true,
  },
  showFilter: {
    type: Boolean,
    default: false,
  },
})

const itemsPerPageOptions = [5, 10, 25, 50, 100, -1];
const isSorted = ref(false);
const itemsPerPage = ref(props.showFilter ? import.meta.env.VITE_TOTAL_VISIBLE_PAGE_NO : import.meta.env.VITE_DASHBOARD_TOTAL_VISIBLE_PAGE_NO);
const totalItems = ref(0);
const loading = ref(false);
const search = ref('');
const notReviewedPatientLists = ref([] as PatientInsightDetail[]);
const dialog = ref(false);
const severityType = ref();
const filterData = ref({});
const sortingData = ref();
const errorMessage = ref('');

type DataTableHeader = {
  key: string;
  value?: string;
  title: string;
  colspan?: number;
  rowspan?: number;
  fixed?: boolean;
  align?: 'start' | 'end';
  width?: number;
  minWidth?: string;
  maxWidth?: string;
  sortable?: boolean;
  sort?: (a: any, b: any) => number;
};
const headers: DataTableHeader[] = [
  {
    title: 'First Name',
    align: 'start',
    key: 'firstName',
  },
  { title: 'Last Name', key: 'lastName', align: 'start' },
  { title: 'Systolic/Diastolic', key: 'lastReading', align: 'start' },
  { title: 'Minutes Spent', key: 'minutesSpent', align: 'start' },
  { title: 'Last Reading (Time)', key: 'lastReadingTime', align: 'start' },

];

const emits = defineEmits(['item-selected'])
const page = ref(1);
const hasFilters = computed(() => {
  return Object.keys(filterData.value).length > 0 && (Object.values(filterData.value).some(value => value));
});
const patientReviewChangeEventBus = useEventBus(patientReviewedEventBusKey);
const patientProgramInsightsEvent = useEventBus(patientProgramInsightsEventBusKey);
const queryClient = useQueryClient();

const loadSeverPatient = () => {
  loading.value = true;
  const offset = (page.value - 1) * itemsPerPage.value;
  const limit = itemsPerPage.value;
  getNotReviewedPatientList(limit, offset, isSorted.value ? sortingData.value : undefined);
};

const paginationChanged = ({ page: updatedPage, itemsPerPage: updatedItemsPerPage }: { page: number; itemsPerPage: number }) => {
  page.value = updatedPage;
  itemsPerPage.value = updatedItemsPerPage;
  loadSeverPatient();
};

const submitNotReviewedPatientFilter = async (patientFiltrationData: SeverityPatientFilter) => {
  dialog.value = false;
  const PatientProgramVitalsFilter = {
    patientName: patientFiltrationData.patientName,
    minutesSpent: Number(patientFiltrationData.minutesSpent),
  } as PatientProgramVitalsFilter;
  filterData.value = PatientProgramVitalsFilter;

  const offset = (page.value - 1) * itemsPerPage.value;
  const limit = itemsPerPage.value;

  await getNotReviewedPatientList(limit, offset, isSorted.value ? sortingData.value : undefined);
}

const removeFilter = (keyToRemove: string) => {
  filterData.value = Object.fromEntries(
    Object.entries(filterData.value).filter(([key]) => key !== keyToRemove)
  );
  loadSeverPatient();
}

const clearFilters = () => {
  filterData.value = {};
  itemsPerPage.value = 10;
  loadSeverPatient();
}

const updateSortBy = (sortedData: ISortData[]) => {
  if (sortedData && sortedData.length > 0) {
    isSorted.value = true;
    const [{ key, order }] = sortedData;

    const keyMapping: Record<string, PatientProgramVitalsSortKey> = {
      firstName: PatientProgramVitalsSortKey.patientFirstName,
      lastName: PatientProgramVitalsSortKey.patientLastName,
      minutesSpent: PatientProgramVitalsSortKey.minutesSpent,
    };

    const sortKey = defaultTo(
      keyMapping[key],
      PatientProgramVitalsSortKey.lastSync
    );

    const sortOrder = order.toUpperCase() as SortOrder;

    const sortBy: PatientProgramVitalsSortBy = {
      sortKey,
      sortOrder,
    };

    const offset = (page.value - 1) * itemsPerPage.value;
    const limit = itemsPerPage.value;

    sortingData.value = sortBy;

    getNotReviewedPatientList(limit, offset, sortBy);
  }
  else {
    isSorted.value = false;
  }
};

const getNotReviewedPatientList = async (limit: number, offset: number, sortBy?: PatientProgramVitalsSortBy) => {
  loading.value = true;
  const filter = {
    vital: Vitals.BP,
    isReviewed: false,
    vitalFilter: {
      severity: severityType.value || VitalsSeverity.HIGH
    },
    ...filterData.value,
  } as PatientProgramVitalsFilter;

  try {
    const response = await apolloClient.query({
      query: PATIENTS_NEEDS_REVIEW,
      variables: {
        limit: limit,
        offset: offset,
        filter: filter,
        sort: sortBy,
      },
      fetchPolicy: 'network-only'
    });

    const severityPatientListData = response?.data.patientsNeedsReview;

    if (!severityPatientListData) {
      return;
    }

    notReviewedPatientLists.value = map(severityPatientListData.results, (patient) => {
      const latestPatientReading = maxBy(patient.patientReadings, 'collectedTimestamp');
      const lastReadingTime = get(latestPatientReading, 'collectedTimestamp', null);
      const lastReading = get(latestPatientReading, 'reading.formatted', null);
      const systolic = get(lastReading, 'systolic', null);
      const diastolic = get(lastReading, 'diastolic', null);
      const lastReadingTooltip = get(latestPatientReading, 'collectedTimestamp', null);

      return {
        ...patient,
        lastReadingTime: lastReadingTime ? useTimeAgo(lastReadingTime).value : 'N/A',
        lastReading: lastReading ? `${systolic}/${diastolic}` : 'N/A',
        minutesSpent: convertMilliSecondsToMinutes(patient.minutesSpent),
        lastReadingTooltip: lastReadingTooltip ? moment(lastReadingTooltip).format('MM/DD/YYYY, h:mm:ss A') : 'No data available',
      };
    });

    totalItems.value = severityPatientListData.count;

  } catch (error) {
    errorMessage.value = (error as Error).message;
    throw error
  } finally {
    loading.value = false;
  }
}

const onRowClick = (
  _meta: unknown,
  event: { item: { raw: PatientInsight } }
) => {
  const data = event.item;
  emits('item-selected', data);
};

watch(() => props.patientVitalType, (newValue) => {
  severityType.value = newValue;
  getNotReviewedPatientList(itemsPerPage.value, 0);
})

onMounted(() => {
  patientReviewChangeEventBus.on(() => {
    console.log('Patient review event bus triggered');
    getNotReviewedPatientList(itemsPerPage.value, 0);
  })

  patientProgramInsightsEvent.on(() => {
    loadSeverPatient();
  })
});
</script>

<template>
    <v-card color="surfContainerHigh" class="w-100 h-100 pa-2">
        <v-card-title class="d-flex align-center pa-2 text-primary bg-surface rounded-lg mb-2 elevation-2">
            <div class="d-flex align-center items-center text-primary">
                <v-icon size="large">mdi-chart-timeline-variant-shimmer</v-icon>
                <div class="d-flex flex-wrap">
                    <span class="ml-4">Current Cycle Billing</span>
                    <span class="v-card-subtitle flex-1-1-100"> Choose patient programs to include for billing </span>
                </div>
            </div>

            <v-select color="primary" v-model="programsForBilling" chips label="Select programs to submit for billing"
                variant="outlined" class="mt-2" density="comfortable" :items="programsAvailableForBilling" multiple
                hide-details />
                <v-spacer />
            <v-select color="primary" v-model="billingDistribution" chips label="Select billing strategy"
                variant="outlined" class="mt-2" density="comfortable" title="title" :items="billingDistributionType"
                 hide-details @update:model-value="getSubscribedPrograms()"/>
                 <v-spacer />
            <v-btn v-if="programsForBilling" color="primary" variant="flat" elevation="3" class="mx-2"
                :disabled="programsForBilling.length < 1" rounded="false" @click="saveSelectedBilling()">
                <v-icon class="mx-2" size="x-large">mdi-content-save-settings </v-icon> Save Selection
            </v-btn>
        </v-card-title>
        <v-divider thickness="1"></v-divider>
        <v-card-text class="pa-0 elevation-2 rounded-lg">
            <v-data-table :items="cptDistributions" :items-per-page="-1">
                <template v-slot:[`header.cptCode`]>
                    <div class="vt-cpt-dist-header">
                        <div>
                            <span>CPT Code</span>
                        </div>
                    </div>
                </template>

                <template v-slot:[`header.ccmBucket`]>
                    <v-card :rounded="false" :disabled="programNotInCycle(Program.CCM)">
                        <v-card-title :class="`vt-cpt-dist-header bg-${priorityBg.ccm}`">
                            <div>
                                <span class="text-h6 font-weight-bold">{{ Program.CCM }}</span>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total QHCP : ${{
                                        programQHCPTotals.ccm
                                        }}</span>&nbsp;
                                </v-chip>

                                <v-chip size="small" variant="tonal"
                                    v-if="!isBillable(Program.CCM, 'QHCP')">
                                    <span class="text-subtitle-2">Unbillable</span></v-chip>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total STAFF : ${{
                                        programStaffTotals.ccm
                                        }}</span>&nbsp;
                                </v-chip>
                                <v-chip size="small" variant="tonal"
                                    v-if="!isBillable(Program.CCM, 'STAFF')">
                                    <span class="text-subtitle-2">Unbillable</span></v-chip>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total OTHER : ${{
                                        programOtherTotals.ccm
                                        }}</span>&nbsp;
                                </v-chip>
                            </div>
                        </v-card-title>
                        <v-card-subtitle v-if="programNotInCycle(Program.CCM)" class="bg-tertiary text-center">
                            <span class="text-subtitle-2 font-weight-bold">CCM unavailable</span>
                        </v-card-subtitle>
                        <v-card-subtitle v-else class="bg-primary text-center">
                            <span class="text-subtitle-2 font-weight-bold">CCM billable</span>
                        </v-card-subtitle>
                    </v-card>
                </template>
                <template v-slot:[`header.cccmBucket`]>
                    <v-card :rounded="false" :disabled="programNotInCycle(Program.CCCM)">
                        <v-card-title :class="`vt-cpt-dist-header bg-${priorityBg.cccm}`">
                            <div>
                                <span class="text-h6 font-weight-bold">{{ Program.CCCM }}</span>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total QHCP : ${{
                                        programQHCPTotals.cccm
                                        }}</span>&nbsp;
                                </v-chip>
                                <v-chip size="small" variant="tonal"
                                    v-if="!isBillable(Program.CCCM, 'QHCP')">
                                    <span class="text-subtitle-2">Unbillable</span></v-chip>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total STAFF : ${{
                                        programStaffTotals.cccm
                                        }}</span>&nbsp;
                                </v-chip>
                                <v-chip size="small" variant="tonal"
                                    v-if="!isBillable(Program.CCCM, 'STAFF')">
                                    <span class="text-subtitle-2">Unbillable</span></v-chip>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total OTHER : ${{
                                        programOtherTotals.cccm
                                        }}</span>&nbsp;
                                </v-chip>
                            </div>
                        </v-card-title>
                        <v-card-subtitle v-if="programNotInCycle(Program.CCCM)" class="bg-tertiary text-center">
                            <span class="text-subtitle-2 font-weight-bold">CCCM unavailable</span>
                        </v-card-subtitle>
                        <v-card-subtitle v-else class="bg-primary text-center">
                            <span class="text-subtitle-2 font-weight-bold">CCCM billable</span>
                        </v-card-subtitle>
                    </v-card>
                </template>
                <template v-slot:[`header.pcmBucket`]>
                    <v-card :rounded="false" :disabled="programNotInCycle(Program.PCM)">
                        <v-card-title :class="`vt-cpt-dist-header bg-${priorityBg.pcm}`">
                            <div>
                                <span class="text-h6 font-weight-bold">{{ Program.PCM }}</span>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total QHCP : ${{
                                        programQHCPTotals.pcm
                                        }}</span>&nbsp;
                                </v-chip>
                                <v-chip size="small" variant="tonal"
                                    v-if="!isBillable(Program.PCM, 'QHCP')">
                                    <span class="text-subtitle-2">Unbillable</span></v-chip>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total STAFF : ${{
                                        programStaffTotals.pcm
                                        }}</span>&nbsp;
                                </v-chip>
                                <v-chip size="small" variant="tonal"
                                    v-if="!isBillable(Program.PCM, 'STAFF')">
                                    <span class="text-subtitle-2">Unbillable</span></v-chip>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total OTHER : ${{
                                        programOtherTotals.pcm
                                        }}</span>&nbsp;
                                </v-chip>
                            </div>
                        </v-card-title>
                        <v-card-subtitle v-if="programNotInCycle(Program.PCM)" class="bg-tertiary text-center">
                            <span class="text-subtitle-2 font-weight-bold">PCM unavailable</span>
                        </v-card-subtitle>
                        <v-card-subtitle v-else class="bg-primary text-center">
                            <span class="text-subtitle-2 font-weight-bold">PCM billable</span>
                        </v-card-subtitle>
                    </v-card>
                </template>
                <template v-slot:[`header.rpmBucket`]>
                    <v-card :rounded="false" :disabled="programNotInCycle(Program.RPM)">
                        <v-card-title :class="`vt-cpt-dist-header bg-${priorityBg.rpm}`">
                            <div>
                                <span class="text-h6 font-weight-bold">{{ Program.RPM }}</span>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total QHCP: ${{
                                        programQHCPTotals.rpm
                                        }}</span>&nbsp;
                                </v-chip>
                                <v-chip size="small" variant="tonal"
                                    v-if="!isBillable(Program.RPM, 'QHCP')">
                                    <span class="text-subtitle-2">Unbillable</span></v-chip>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total STAFF: ${{
                                        programStaffTotals.rpm
                                        }}</span>&nbsp;
                                </v-chip>
                                <v-chip size="small" variant="tonal"
                                    v-if="!isBillable(Program.RPM, 'STAFF')">
                                    <span class="text-subtitle-2">Unbillable</span></v-chip>
                            </div>
                            <div>
                                <v-chip class="ma-1" variant="tonal" size="small">
                                    <span class="vt-cpt-program-block__time text-subtitle-1">Total OTHER : ${{
                                        programOtherTotals.rpm
                                        }}</span>&nbsp;
                                </v-chip>
                            </div>
                        </v-card-title>
                        <v-card-subtitle v-if="programNotInCycle(Program.RPM)" class="bg-tertiary text-center">
                            <span class="text-subtitle-2 font-weight-bold">RPM unavailable</span>
                        </v-card-subtitle>
                        <v-card-subtitle v-else class="bg-primary text-center">
                            <span class="text-subtitle-2 font-weight-bold">RPM billable</span>
                        </v-card-subtitle>
                    </v-card>
                </template>

                <template v-slot:[`item.cptCode`]="{ item }">
                    <span
                        :class="`${ getCPTCodeBucketColor(item.cptCode.bucket) } vt-cpt-program-block text-h6 font-weight-bold`">{{
                            item.cptCode.code }}</span>
                </template>

                <template v-slot:[`item.ccmBucket`]="{ item }">
                    <v-card :color="priorityBg.ccm" :variant="programNotInCycle(Program.CCM) ? 'outlined' : 'tonal'"
                        class="h-100" :rounded="false" :disabled="programNotInCycle(Program.CCM)">
                        <template v-if="item.ccmBucket">

                            <div class="vt-cpt-program-block" v-if="item.cptCode.bucket !== 'OTHER'">

                                <span class="vt-cpt-program-block__time"> {{
                                    convertMillisecondsToMinsSecs(item.ccmBucket?.timeInCycle) }}</span>

                            </div>
                            <div class="vt-cpt-program-details">
                                <v-chip class="mx-1" variant="tonal" color="info" size="small">
                                    <span class="vt-cpt-program-block__count">count: {{ item.ccmBucket?.billingCount
                                        }}</span>
                                </v-chip>
                                <v-chip class="mx-1" variant="tonal" color="tertiary" size="small">
                                    <span class="vt-cpt-program-block__revenue">${{ round(item.ccmBucket?.revenue, 2)
                                        }}</span>
                                </v-chip>
                            </div>

                        </template>
                        <div v-else class="vt-cpt-program-na">
                            <v-chip variant="tonal" color="error" size="small">
                                <span class="vt-cpt-program-block__na">NA</span>
                            </v-chip>
                        </div>
                    </v-card>
                </template>

                <template v-slot:[`item.cccmBucket`]="{ item }">
                    <v-card :color="priorityBg.cccm" :variant="programNotInCycle(Program.CCCM) ? 'outlined' : 'tonal'"
                        class="h-100" :rounded="false" :disabled="programNotInCycle(Program.CCCM)">
                        <template v-if="item.cccmBucket">
                            <div class="vt-cpt-program-block" v-if="item.cptCode.bucket !== 'OTHER'">

                                <span class="vt-cpt-program-block__time"> {{
                                    convertMillisecondsToMinsSecs(item.cccmBucket?.timeInCycle) }}</span>

                            </div>
                            <div class="vt-cpt-program-details">
                                <v-chip class="mx-1" variant="tonal" color="info" size="small">
                                    <span class="vt-cpt-program-block__count">count: {{ item.cccmBucket?.billingCount
                                        }}</span>
                                </v-chip>
                                <v-chip class="mx-1" variant="tonal" color="tertiary" size="small">
                                    <span class="vt-cpt-program-block__revenue">${{ round(item.cccmBucket?.revenue, 2)
                                        }}</span>
                                </v-chip>
                            </div>
                        </template>
                        <div v-else class="vt-cpt-program-na">
                            <v-chip variant="tonal" color="error" size="small">
                                <span class="vt-cpt-program-block__na">NA</span>
                            </v-chip>
                        </div>
                    </v-card>
                </template>

                <template v-slot:[`item.pcmBucket`]="{ item }">
                    <v-card :color="priorityBg.pcm" :variant="programNotInCycle(Program.PCM) ? 'outlined' : 'tonal'"
                        class="h-100" :rounded="false" :disabled="programNotInCycle(Program.PCM)">
                        <template v-if="item.pcmBucket">
                            <div class="vt-cpt-program-block" v-if="item.cptCode.bucket !== 'OTHER'">

                                <span class="vt-cpt-program-block__time"> {{
                                    convertMillisecondsToMinsSecs(item.pcmBucket?.timeInCycle) }}</span>

                            </div>
                            <div class="vt-cpt-program-details">
                                <v-chip class="mx-1" variant="tonal" color="info" size="small">
                                    <span class="vt-cpt-program-block__count">count: {{ item.pcmBucket?.billingCount
                                        }}</span>
                                </v-chip>
                                <v-chip class="mx-1" variant="tonal" color="tertiary" size="small">
                                    <span class="vt-cpt-program-block__revenue">${{ round(item.pcmBucket?.revenue, 2)
                                        }}</span>
                                </v-chip>
                            </div>
                        </template>
                        <div v-else class="vt-cpt-program-na">
                            <v-chip variant="tonal" color="error" size="small">
                                <span class="vt-cpt-program-block__na">NA</span>
                            </v-chip>
                        </div>
                    </v-card>
                </template>

                <template v-slot:[`item.rpmBucket`]="{ item }">
                    <v-card :color="priorityBg.rpm" :variant="programNotInCycle(Program.RPM) ? 'outlined' : 'tonal'"
                        class="h-100" :rounded="false" :disabled="programNotInCycle(Program.RPM)">
                        <template v-if="item.rpmBucket">
                            <div class="vt-cpt-program-block">

                                <span class="vt-cpt-program-block__time" v-if="item.cptCode.bucket !== 'OTHER'"> {{
                                    convertMillisecondsToMinsSecs(item.rpmBucket?.timeInCycle) }}</span>

                            </div>
                            <div class="vt-cpt-program-details my-2">
                                <v-chip class="mx-1" variant="tonal" color="info" size="small">
                                    <span class="vt-cpt-program-block__count">count: {{ item.rpmBucket?.billingCount
                                        }}</span>
                                </v-chip>
                                <v-chip class="mx-1" variant="tonal" color="tertiary" size="small">
                                    <span class="vt-cpt-program-block__revenue">${{ round(item.rpmBucket?.revenue, 2)
                                        }}</span>
                                </v-chip>
                            </div>
                        </template>
                        <div v-else class="vt-cpt-program-na">
                            <v-chip variant="tonal" color="error" size="small">
                                <span class="vt-cpt-program-block__na">NA</span>
                            </v-chip>
                        </div>
                    </v-card>
                </template>

                <template v-slot:top>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="2" class="d-flex align-center flex-column">
                                <div class="text-primary text-center d-flex flex-column">
                                    <span class="text-subtitle-2"> Cycle Start Date &nbsp;</span>
                                    <v-chip>
                                        <span class="text-subtitle-1 font-weight-black">{{
                                            billingCycleDates.currentMonthStartString }}</span>
                                    </v-chip>
                                </div>
                            </v-col>
                            <v-col cols="2" class="d-flex align-center flex-column">
                                <div class="text-primary text-center d-flex flex-column">
                                    <span class="text-subtitle-2"> End Date &nbsp; </span>
                                    <v-chip>
                                        <span class="text-subtitle-1 font-weight-bold">{{
                                            billingCycleDates.currentMonthEndString }}</span>
                                    </v-chip>

                                </div>
                            </v-col>
                            <v-col cols="2" class="d-flex flex-column">
                                <div class="text-primary d-flex-column text-center">
                                    <span class="text-subtitle-2"> Potential Reimbursement</span>
                                    <v-chip>
                                        <span class="text-h6 font-weight-black"><span
                                                class="text-subtitle-1 text-primary">QHCP:&nbsp;<span
                                                    class="text-subtitle-1 font-weight-bold">
                                                    ${{ potentialQHCPReimbursementInCycle }}</span>
                                            </span>
                                        </span>
                                    </v-chip>
                                </div>
                            </v-col>
                            <v-col cols="2" class="d-flex flex-column">
                                <div class="text-primary d-flex-column text-center">
                                    <span class="text-subtitle-2"> Potential Reimbursement</span>
                                    <v-chip>
                                        <span class="text-subtitle-1 text-primary">STAFF:&nbsp;<span
                                                class="text-subtitle-1 font-weight-black">
                                                ${{ potentialStaffReimbursementInCycle }}</span></span>
                                    </v-chip>
                                </div>
                            </v-col>
                            <v-col cols="2" class="d-flex flex-column">
                                <div class="text-primary d-flex-column text-center">
                                    <span class="text-subtitle-2"> Potential Reimbursement</span>
                                    <v-chip>
                                        <span class="text-subtitle-1 text-primary">OTHER:&nbsp;<span
                                                class="text-subtitle-1 font-weight-black">
                                                ${{ potentialOtherReimbursementInCycle }}</span></span>
                                    </v-chip>
                                </div>
                            </v-col>
                            <v-col cols="2" class="d-flex flex-column">
                                <div class="text-primary text-center d-flex flex-column">
                                    <span class="text-subtitle-1 text-primary"> Programs selected for billing
                                        &nbsp;</span>
                                    <div>
                                        <template v-if="patientBillingCycle?.programsSelectedForBilling">
                                            <v-chip size="x-small" class="mx-1"
                                                v-for="(programCycle, prgIndex) in patientBillingCycle?.programsSelectedForBilling"
                                                :key="prgIndex"><span class="text-subtitle-2 font-weight-bold">
                                                    {{ programCycle.program }}</span> </v-chip>
                                        </template>
                                        <template v-if="isEmpty(patientBillingCycle?.programsSelectedForBilling)">
                                            <span class="text-subtitle-2 font-weight-bold">
                                                No selections yet</span>
                                        </template>
                                    </div>

                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>

                <template v-slot:bottom>
                    <v-container fluid class="bg-surface">
                        <!--overriding bottom slot with empty container to hide pagination-->
                    </v-container>
                </template>

            </v-data-table>
        </v-card-text>

    <v-snackbar v-model="showSuccessSnackbar" color="success" location="top right" class="mt-16">Billing configuration
      saved successfully
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showSuccessSnackbar = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar v-model="showErrorSnackbar" color="success" location="top right" class="mt-16">Failed to save Billing
      configuration.
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showErrorSnackbar = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar v-model="showLoadConfigurationErrorSnackbar" color="error" location="top right" class="mt-16">Failed
      toload Billing configuration.
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showLoadConfigurationErrorSnackbar = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <Loader :overlay="loading" />
  </v-card>
</template>

<script setup lang="ts">
import { getCurrentCycleDates } from '@/composables/cycle.composable';
import { convertMillisecondsToMinsSecs } from '@/composables/DateUtility';
import { ProgramCycleType, BillingDistributionTypes, BillingDistributionType } from '@/enums/patient-program-cycle.enum';
import { Program } from '@/enums/patient-program.enum';
import { billingProgramSubscribeEventBusKey } from '@/events/bus-keys/billing-program-subscription-event.bus-keys';
import { IBillingCPTDistribution, IPatientBillingCycle, ProgramBucketDistribution } from '@/interfaces/billing.interface';
import { useEventBus } from '@vueuse/core';
import { filter, has, includes, isEmpty, map, omit, round, sumBy, findKey, isNull, pick } from 'lodash';
import { PropType, onMounted, ref, watch } from 'vue';
import { IAdditionalBillingInfo, IPatientProgramCycle } from '../../../../../interfaces/patient-program-cycle.interface';
import Loader from "../../../../common/Loader.vue";
import { ISource } from '@/interfaces/source.interface';

const { patientId, dataLoader, dataPersister, patientSourcesLoader } = defineProps({
    patientId: {
        type: String,
        required: true
    },
    dataLoader: {
        type: Function as PropType<(patientId: string, billingDistribution:BillingDistributionType) => Promise<IPatientBillingCycle>>,
        required: true
    },
    dataPersister: {
        type: Function as PropType<(patientId: string, data: { patientBillingCycle: IPatientBillingCycle, programsForBilling: IPatientProgramCycle[] }) => Promise<{ patientBillingCycle: IPatientBillingCycle, programsForBilling: IPatientProgramCycle[] }>>,
        required: true
    },
    patientSourcesLoader: {
    type: Function as PropType<(patientId: string) => Promise<ISource[]>>,
    required: true,
  },
});
const billingCycleDates = ref(getCurrentCycleDates(ProgramCycleType.MONTHLY));
const patientBillingCycle = ref<IPatientBillingCycle>();
const cptDistributions = ref<IBillingCPTDistribution[]>([]);
const programsAvailableForBilling = ref<{ title: Program, value: IPatientProgramCycle, props?: { disabled?: Boolean } }[]>([]);
const programsForBilling = ref<IPatientProgramCycle[]>([]);
const potentialReimbursementInCycle = ref(0);
const selectedReimbursementTotal = ref(0);
const potentialQHCPReimbursementInCycle = ref(0);
const potentialStaffReimbursementInCycle = ref(0);
const potentialOtherReimbursementInCycle = ref(0);
const programTotals = ref<Record<string, number>>({
    ccm: 0,
    cccm: 0,
    pcm: 0,
    rpm: 0
});
const programStaffTotals = ref<Record<string, number>>({
    ccm: 0,
    cccm: 0,
    pcm: 0,
    rpm: 0
});
const programQHCPTotals = ref<Record<string, number>>({
    ccm: 0,
    cccm: 0,
    pcm: 0,
    rpm: 0
});
const programOtherTotals = ref<Record<string, number>>({
    ccm: 0,
    cccm: 0,
    pcm: 0,
    rpm: 0
});
const loading = ref(false);
const missingPatientDeviceAssociation = ref(false);

const priorityBg = ref<Record<string, string>>({
    ccm: 'success',
    cccm: 'info',
    pcm: 'tertiary',
    rpm: 'warning'
});

const showSuccessSnackbar = ref(false);
const showErrorSnackbar = ref(false);
const showLoadConfigurationErrorSnackbar = ref(false);
const billingProgramSubscribeEventBus = useEventBus(billingProgramSubscribeEventBusKey);
const additionalBillingInfo = ref({} as IAdditionalBillingInfo);
const billingDistributionType = BillingDistributionTypes.filter(billingType => { return billingType });
const billingDistribution = ref(BillingDistributionType.STANDARDIZED);

watch(programTotals.value, (newProgramTotals) => {
    const entries = Object.entries(newProgramTotals);
    entries.sort((a, b) => {
        if (b[1] !== a[1]) {
            return b[1] - a[1]; // Sort by value descending
        } else {
            return a[0].localeCompare(b[0]); // If values are equal, sort keys alphabetically
        }
    });
    const priorityTypes = ['success', 'info', 'warning', 'error'];
    entries.forEach((entry, index) => {
        const key = entry[0];
        const priorityIndex = index < priorityTypes.length ? index : priorityTypes.length - 1;
        priorityBg.value[key] = priorityTypes[priorityIndex];
    });

    potentialReimbursementInCycle.value = entries.filter(entry => {
        const programsForBilling = map(patientBillingCycle.value?.programsInCycle, prgInCycle => {
            return prgInCycle.program;
        });
        const prgEntry = entry[0].toUpperCase() as keyof typeof Program;
        return includes(programsForBilling, prgEntry);
    }).reduce((acc, curr) => {
        return acc + curr[1];
    }, 0);
    potentialReimbursementInCycle.value = round(potentialReimbursementInCycle.value, 2);

    selectedReimbursementTotal.value = entries.filter(entry => {
        const programsForBilling = map(patientBillingCycle.value?.programsSelectedForBilling, 'program');
        const prgEntry = entry[0].toUpperCase() as keyof typeof Program;
        return includes(programsForBilling, prgEntry);
    }).reduce((acc, curr) => {
        return acc + curr[1];
    }, 0);
    selectedReimbursementTotal.value = round(selectedReimbursementTotal.value, 2);
});

watch(programsForBilling, (newProgramsForBilling, oldProgramsForBilling) => {
    handleCCMAndCCCMSubscriptions(oldProgramsForBilling, newProgramsForBilling);
});

const handleCCMAndCCCMSubscriptions = (programSelectedForBilling: IPatientProgramCycle[], newProgramsForBilling: IPatientProgramCycle[]) => {
    const newSelectedPrograms = newProgramsForBilling.map(program => program.program);

    const removablePrograms = newSelectedPrograms.includes(Program.CCM) ? [Program.CCCM] : newSelectedPrograms.includes(Program.CCCM) ? [Program.CCM] : [];

  if (missingPatientDeviceAssociation.value) {
    removablePrograms.push(Program.RPM);
  }

    programsAvailableForBilling.value = programsAvailableForBilling.value.map(programCycle => {
        return {
            ...programCycle,
            props: { disabled: removablePrograms.includes(programCycle.value.program) }
        }
    });

}

const calculateBucketSum = (distribution: IBillingCPTDistribution[], bucketType: string) => {
    const staffSum = sumBy(
        filter(distribution, item => item.cptCode.bucket === 'STAFF' && has(item, `${bucketType}.revenue`)),
        `${bucketType}.revenue`
    );

    const qhcpSum = sumBy(
        filter(distribution, item => item.cptCode.bucket === 'QHCP' && has(item, `${bucketType}.revenue`)),
        `${bucketType}.revenue`
    );

    const otherSum = sumBy(
        filter(distribution, item => item.cptCode.bucket === 'OTHER' && has(item, `${bucketType}.revenue`)),
        `${bucketType}.revenue`
    );

    return {
        staff: round(staffSum, 2),
        qhcp: round(qhcpSum, 2),
        other: round(otherSum, 2),
    };
};

watch(cptDistributions, (newCPTDistribution) => {
    potentialStaffReimbursementInCycle.value = 0;
    potentialQHCPReimbursementInCycle.value = 0;
    potentialOtherReimbursementInCycle.value = 0;
    if (!isEmpty(newCPTDistribution)) {
        programStaffTotals.value.ccm = calculateBucketSum(newCPTDistribution, 'ccmBucket').staff;
        programStaffTotals.value.rpm = calculateBucketSum(newCPTDistribution, 'rpmBucket').staff;
        programStaffTotals.value.cccm = calculateBucketSum(newCPTDistribution, 'cccmBucket').staff;
        programStaffTotals.value.pcm = calculateBucketSum(newCPTDistribution, 'pcmBucket').staff;

        programQHCPTotals.value.ccm = calculateBucketSum(newCPTDistribution, 'ccmBucket').qhcp;
        programQHCPTotals.value.rpm = calculateBucketSum(newCPTDistribution, 'rpmBucket').qhcp;
        programQHCPTotals.value.cccm = calculateBucketSum(newCPTDistribution, 'cccmBucket').qhcp;
        programQHCPTotals.value.pcm = calculateBucketSum(newCPTDistribution, 'pcmBucket').qhcp;

        programOtherTotals.value.ccm = calculateBucketSum(newCPTDistribution, 'ccmBucket').other;
        programOtherTotals.value.rpm = calculateBucketSum(newCPTDistribution, 'rpmBucket').other;
        programOtherTotals.value.cccm = calculateBucketSum(newCPTDistribution, 'cccmBucket').other;
        programOtherTotals.value.pcm = calculateBucketSum(newCPTDistribution, 'pcmBucket').other;

        calcuateForSpecificBuckets(newCPTDistribution);
    }
}, { deep: true })

const calcuateForSpecificBuckets = (newCPTDistribution: IBillingCPTDistribution[]) => {
    let qhcpBillableRevenue = 0;
    let staffBillableRevenue = 0;
    let otherBillableRevenue = 0;
    newCPTDistribution.forEach(item => {
        if (additionalBillingInfo.value) {
            Object.keys(additionalBillingInfo.value).forEach(program => {
                const bucket = additionalBillingInfo.value[program] ? additionalBillingInfo.value[program].billableBucket : '';
                const bucketName = `${program.toLowerCase()}Bucket` as keyof IBillingCPTDistribution;
                if (item[bucketName] && bucket === item.cptCode.bucket) {
                    const revenue = (item[bucketName] as ProgramBucketDistribution).revenue;
                    if (bucket === 'QHCP') {
                        qhcpBillableRevenue += revenue;
                    } else if (bucket === 'STAFF') {
                        staffBillableRevenue += revenue;
                    }
                }
            });
        }

        if (item.cptCode.bucket === 'OTHER') {
            const availableBuckets = pick(item, ['cccmBucket', 'ccmBucket', 'pcmBucket', 'rpmBucket']);
            const validBucket = findKey(availableBuckets, value => !isNull(value));
            if (validBucket) {
                otherBillableRevenue += (item[validBucket as keyof IBillingCPTDistribution] as ProgramBucketDistribution).revenue;
            }
        }
    });
    potentialStaffReimbursementInCycle.value = round(staffBillableRevenue, 2);
    potentialQHCPReimbursementInCycle.value = round(qhcpBillableRevenue, 2);
    potentialOtherReimbursementInCycle.value = round(otherBillableRevenue, 2);
}


const getProgramTotal = (programCode: Program) => {
    const programTotal = cptDistributions.value.reduce((acc, curr) => {
        if (programCode === Program.CCM) {
            const ccmTotal = acc + (curr.ccmBucket?.revenue ?? 0);
            programTotals.value.ccm = ccmTotal;
            return round(ccmTotal, 2);
        } else if (programCode === Program.CCCM) {
            const cccmTotal = acc + (curr.cccmBucket?.revenue ?? 0);
            programTotals.value.cccm = cccmTotal;
            return round(cccmTotal, 2);
        } else if (programCode === Program.PCM) {
            const pcmTotal = acc + (curr.pcmBucket?.revenue ?? 0);
            programTotals.value.pcm = pcmTotal;
            return round(pcmTotal, 2);
        } else if (programCode === Program.RPM) {
            const rpmTotal = acc + (curr.rpmBucket?.revenue ?? 0);
            programTotals.value.rpm = rpmTotal;
            return round(rpmTotal, 2);
        }
        return acc;
    }, 0);

    return programTotal;
};

const programNotInCycle = (programCode: Program) => {
    return !includes(map(patientBillingCycle.value?.programsInCycle, prgInCycle => prgInCycle.program), programCode);
};

const saveSelectedBilling = () => {
    loading.value = true;
    dataPersister(patientId, { patientBillingCycle: patientBillingCycle.value!, programsForBilling: programsForBilling.value }).then((data) => {
        getSubscribedPrograms();
        showSuccessSnackbar.value = true;
        loading.value = false;
    }).catch((error) => {
        loading.value = false;
        showErrorSnackbar.value = true;
    });
};

const getSubscribedPrograms = () => {
    loading.value = true;
    dataLoader(patientId, billingDistribution.value).then((data) => {
      if(data){
        patientBillingCycle.value = data;
        cptDistributions.value = map(data.billingDistributions, distribution => omit(distribution, '__typename'));
        additionalBillingInfo.value = data.additionalBillingInfo;

        programsAvailableForBilling.value = map(data.programsInCycle, prg => {
            return {
                title: prg.program,
                value: prg
            }
        });
        programsForBilling.value = map(data.programsSelectedForBilling, program => {
            return program
        });
      }

      const isRPMCalculatedForBilling = programsForBilling.value.filter(device => device.program === "RPM");

      if (missingPatientDeviceAssociation.value && !isEmpty(isRPMCalculatedForBilling)) {
        programsForBilling.value = programsForBilling.value.filter(device => device.program !== "RPM");
        saveSelectedBilling();
      }

      loading.value = false;
    }).catch((e) => {
        loading.value = false;
        showLoadConfigurationErrorSnackbar.value = true;
    });
}

const isBillable = (program: Program, bucket: string) => {
    return (!additionalBillingInfo.value || !additionalBillingInfo.value[program]) || additionalBillingInfo.value[program].billableBucket === bucket;
}

const getCPTCodeBucketColor = (bucket: string) => {
  const cptCodeBucketColorHandler: Record<string, string> = {
    'QHCP': 'text-info',
    'STAFF': 'text-success',
    'OTHER': 'text-brown',
    'DEFAULT': 'text-blue-grey-darken-2',
  };
  const cptCodeBucketColor = cptCodeBucketColorHandler[bucket] || cptCodeBucketColorHandler['DEFAULT'];

  return cptCodeBucketColor;
}

onMounted(async () => {
  const patientAssociatedDevices = await patientSourcesLoader(patientId);
  missingPatientDeviceAssociation.value = isEmpty(patientAssociatedDevices);

  getSubscribedPrograms();
  billingProgramSubscribeEventBus.on(getSubscribedPrograms);

});
</script>

<style scoped lang="scss">
.vt-cpt-program-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.25rem;

    &__time {
        font-size: 1.2rem;
        font-weight: bold;
    }

    &__count {
        font-size: 0.75rem;
        font-weight: bold;
    }

    &__revenue {
        font-size: 0.75rem;
        font-weight: bold;
    }

    &__na {
        font-size: 0.75rem;
        font-weight: bold;
    }
}

.vt-cpt-program-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0.25rem;
}

.vt-cpt-dist-header {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    padding: 1rem;
}

.vt-cpt-program-na {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}


.v-card--disabled {
    opacity: 0.85;
}
</style>

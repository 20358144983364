import gql from "graphql-tag";

export const FILE_UPLOAD_TOKEN = gql`
  mutation getFileUploadToken($patientId: String!) {
    getFileUploadToken(patientId: $patientId)
  }
`;

export const CREATE_PATIENT_DOCUMENT = gql`
  mutation createPatientDocument($documentInput: DocumentInput!) {
    createPatientDocument(documentInput: $documentInput) {
      documentId
      documentKey
      title
      createdAt
      type
      metaData {
        orgUser {
          id
          firstName
          lastName
        }
        action
        actionAt
      }
    }
  }
`;

export const PATIENT_DOCUMENTS = gql`
  query patientDocuments($patientId: String!, $filter: DocumentFilter) {
    patientDocuments(patientId: $patientId, filter: $filter) {
      totalCount
      patientId
      documentData {
        documentId
        documentKey
        title
        createdAt
        type
        metaData {
          orgUser {
            id
            firstName
            lastName
          }
          action
          actionAt
        }
      }
    }
  }
`;

export const DELETE_PATIENT_DOCUMENT = gql`
  mutation deletePatientUploadedDocument(
    $patientId: String!
    $documentId: String!
    $documentKey: String!
    $isSavedFile: Boolean!
  ) {
    deletePatientUploadedDocument(
      patientId: $patientId
      documentId: $documentId
      documentKey: $documentKey
      isSavedFile: $isSavedFile
    )
  }
`;

export const DOWNLOAD_FILE = gql`
  query documentUrl(
    $patientId: String!
    $documentId: String!
    $documentKey: String!
  ) {
    documentUrl(
      patientId: $patientId
      documentId: $documentId
      documentKey: $documentKey
    )
  }
`;

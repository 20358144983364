<template>
  <v-card class="h-100 mx-2 pa-2" color="surfContainer" elevation="2">
    <v-card-item class="bg-surface mb-2">
      <template v-slot:title>
        <div class="d-flex align-center items-center text-primary">
          <v-icon size="large">mdi-clipboard-pulse-outline</v-icon>
          <div class="w-50 d-flex flex-wrap">
            <span class="ml-4"> Vitals Configuration </span>
            <span class="ml-4 v-card-subtitle flex-1-1-100">Configure default thresholds for vitals
              {{ patientId ? 'of the patient' : 'across your organization' }}</span>
          </div>
          <v-spacer />
        </div>
      </template>
    </v-card-item>
    <v-skeleton-loader v-if="loading" color="surface" type="card"></v-skeleton-loader>
    <v-card-text class="pa-0" v-else>
        <component :is="vitalConfigForm" :vitalBPConfigurationData="fetchVitalsConfigurationData" :patientId="patientId"
        :vital-criteria-compact-view="vitalCriteriaCompactView"
        @emitted:vitalBPConfigurationData="emittedData"/>
    </v-card-text>
  </v-card>
  <v-snackbar color="success" v-model="showVitalSavedSuccessMsg" location="top right" class="mt-16">Vitals configured
    successfully.
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showVitalSavedSuccessMsg = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<script setup lang="ts">
import { ref, shallowRef, PropType, onMounted } from 'vue';
import VitalBPFormComp from "./VitalBPFormComp.vue"
import { IPatientVitalConfigureResponse, IVitalConfigure, IVitalConfigureResponse, PatientVitalsInput, VitalsInput } from '@/interfaces/IVitalConfigure';


const vitalConfigForm = shallowRef();
const vitalList = ['BP'];
const vitalType = ref('');
const loading = ref(false);
const showVitalSavedSuccessMsg = ref(false);
const onVitalSelected = async (vitalType: any) => {
  if (vitalType === 'BP') {
    await getVitalsConfigurationData();
    vitalConfigForm.value = VitalBPFormComp;
  }
};
const { patientId, patientVitalConfigLoader, dataLoader, addVitalHandler, savePatientVitalConfigHandler } = defineProps({
  patientId: {
    type: String,
    required: false
  },
  dataLoader: {
    type: Function as PropType<() => Promise<IVitalConfigureResponse>>,
    required: false,
  },
  patientVitalConfigLoader: {
    type: Function as PropType<(patientId: string) => Promise<IPatientVitalConfigureResponse>>,
    required: false,
  },
  addVitalHandler: {
    type: Function as PropType<(vitalInput: VitalsInput) => Promise<IVitalConfigure>>,
    required: false,
  },
  savePatientVitalConfigHandler: {
    type: Function as PropType<(patientVitalInput: PatientVitalsInput) => Promise<IVitalConfigure>>,
    required: false,
  },
  vitalCriteriaCompactView: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const fetchVitalsConfigurationData = ref(null as null | IVitalConfigure);
const configurationVitalId = ref('');

const getVitalsConfigurationData = async () => {
  if (patientId && patientVitalConfigLoader) {
    loading.value = true;
    const thresholdConfigRes = await patientVitalConfigLoader(patientId);
    if (thresholdConfigRes?.getVitalsThresholdConfigForPatient) {
      const { getVitalsThresholdConfigForPatient: thresholdConfig } = thresholdConfigRes;
      fetchVitalsConfigurationData.value = thresholdConfig;
    }
    loading.value = false;
  } else if (dataLoader) {
    loading.value = true;
    const thresholdConfigRes = await dataLoader();
    configurationVitalId.value = thresholdConfigRes.data.getVitalsThresholdConfig.id as string;
    if (thresholdConfigRes.data?.getVitalsThresholdConfig) {
      const { data: { getVitalsThresholdConfig: thresholdConfig } } = thresholdConfigRes;
      fetchVitalsConfigurationData.value = thresholdConfig;
    }
  }
  loading.value = false;
};


const emittedData = async (data: any) => {
  if (patientId && savePatientVitalConfigHandler) {
    loading.value = true;
    await savePatientVitalConfigHandler({
      config: { ...data.config },
      patientId: patientId,
      vital: vitalType.value as string,
      reportingFrequency: { ...data.reportingFrequency }
    });
    loading.value = false;
    showVitalSavedSuccessMsg.value = true;
  } else {
    loading.value = true;
    await addVitalHandler?.({
      id: configurationVitalId.value,
      vital: vitalType.value as string,
      config: { ...data.config },
      reportingFrequency: { ...data.reportingFrequency }
    });
    loading.value = false;
    showVitalSavedSuccessMsg.value = true;
  }
  getVitalsConfigurationData();
}
onMounted(() => {
  if (vitalList.length === 1) {
    vitalType.value = vitalList[0];
    onVitalSelected(vitalType.value);
  }
})
</script>

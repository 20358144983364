import { first } from 'lodash';
<template>
  <v-card color="surfContainerHigh" elevation="2" class="h-100">
    <v-toolbar density="compact" color="surface" class="px-4">
      <v-toolbar-title class="d-flex justify-start text-primary">
        <div class="d-flex flex-wrap align-center text-h6">
          <v-icon size="large">mdi-set-center-right</v-icon>
          <span class="ml-2"> Program Insights</span>
        </div>
      </v-toolbar-title>
      <v-tabs color="primary" class="bg-surface text-primary mx-4" density="compact" v-model="currentTab">
        <v-tab :value="PROGRAM_INSIGHTS_TABS.WELLNESS" class="pa-4 mx-2" rounded="false">
          <v-icon size="large">mdi-heart-flash</v-icon>
          <v-badge :content="wellnessInsightsCount" :max="99" color="success">
            <span class="text-subtitle-1 font-weight-bold text-capitalize mx-2">Wellness</span>
          </v-badge>
        </v-tab>
        <v-tab :value="PROGRAM_INSIGHTS_TABS.ADHERENCE" class="pa-4 mx-2" rounded="false">
          <v-icon size="large">mdi-timer-sand-complete</v-icon>
          <v-badge :content="inadherentCount" :max="99" color="warning">
            <span class="text-subtitle-1 font-weight-bold text-capitalize mx-2">Adherence</span>
          </v-badge>
        </v-tab>
        <v-tab :value="PROGRAM_INSIGHTS_TABS.REVIEW" class="pa-4 mx-2" rounded="false">
          <v-icon size="large">mdi-clipboard-pulse</v-icon>
          <v-badge :content="inReviewCount" :max="99" color="onSurfaceVar">
            <span class="text-subtitle-1 font-weight-bold text-capitalize mx-2">Review</span>
          </v-badge>
        </v-tab>
      </v-tabs>
      <v-spacer />
      <page-sizer v-if="!!patientProfileProps.patientId" :initial-selection="initPageSizeSelection" color="primary"
        @page-size-change="handlePageSizeChange" />
    </v-toolbar>
    <v-divider thickness="1" />
    <v-card-item class="text-primary ">
      <v-container fluid class="pa-2">
        <v-row>
          <v-col v-if="listCols > 0" :cols="listCols" class="bg-surfContainerHigh">
            <component :is="TAB_COMPONENTS[currentTab]" :only-content="true" :show-filter="true"
              @insight-selection="handlePatientSelection" />
          </v-col>
          <v-col v-if="monitorCols > 0" :cols="monitorCols" class="bg-surfContainerHigh">
            <v-skeleton-loader v-if="patientProfileLoading" class="mx-auto"
              type="card-avatar, table-heading,  image,table-row-divider,  table-row-divider , table-row-divider, table-tfoot, article, actions, ossein"
              color="surface" />
            <template v-else>
              <component v-if="!!patientProfileProps.patientId" :is="patientProfileComponent" :compact-view="true"
                :is-dashboard-view="true" :patient-profile="patientProfileProps"
                :get-dependencies="getPatientMonitorDependencies"
                :vital-criteria-compact-view="true"
                @request-patient-monitor-exit="handlePatientMonitorExit" elevation="2" />
              <template v-else>
                <no-patient-selected />
              </template>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card-item>
  </v-card>
</template>
<script setup lang="ts">
import { ref, reactive, shallowRef, computed, PropType, onMounted } from 'vue';
import RPMCompliancePriorityWidget from '@/components/widgets/custom/RPMCompliancePriorityWidget.vue';
import RPMReviewPriorityWidget from '@/components/widgets/custom/RPMReviewPriorityWidget.vue';
import RPMSeverityPriorityWidget from '@/components/widgets/custom/RPMSeverityPriorityWidget.vue';
import PatientMonitor from '@/components/patient/profile/PatientMonitor.vue';
import NoPatientSelected from '@/components/dashboard/screens/NoPatientSelected.vue';
import PageSizer, { PAGE_SIZER } from '@/components/common/utils/PageSizer.vue';
import { getPatientMonitorDependencies } from '@/services/PatientMonitor.service';
import { IPatientInfo, IPatientProfileProps } from '@/interfaces/patient.interface';
import { GenderLabel } from '@/enums/genderType';
import { Gender } from '../../enums/genderType';
import { PatientInsight } from '@/interfaces/patientInsights.interface';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { getPatientInsightsOverview } from '@/services/patient-program-insight.service';
import { Program } from '@/enums/patientInsights.enum';
import { VITAL } from '@/enums/readings';
import { useEventBus } from '@vueuse/core';
import { patientProgramInsightsEventBusKey } from '@/events/bus-keys/patient-program-insights-event.bus-key';

const TAB_COMPONENTS = {
  [PROGRAM_INSIGHTS_TABS.WELLNESS]: RPMSeverityPriorityWidget,
  [PROGRAM_INSIGHTS_TABS.ADHERENCE]: RPMCompliancePriorityWidget,
  [PROGRAM_INSIGHTS_TABS.REVIEW]: RPMReviewPriorityWidget,
};
const { startTab } = defineProps({
  startTab: {
    type: String as PropType<PROGRAM_INSIGHTS_TABS>,
    default: PROGRAM_INSIGHTS_TABS.WELLNESS,
  },
});

const currentTab = ref<PROGRAM_INSIGHTS_TABS>(startTab);
const patientProfileComponent = shallowRef();

const patientProfileLoading = ref(false);
const listCols = ref(5);
const monitorCols = ref(7);
const initPageSizeSelection = ref(PAGE_SIZER.SPLIT_SCREEN);
const patientProfileKey = ref(null as string | null);

const patientProfileProps = reactive({} as IPatientProfileProps);
const patientProgramInsightsEvent = useEventBus(patientProgramInsightsEventBusKey);
const queryClient = useQueryClient();

const { data: programInsights, isLoading: insightsLoading } = useQuery({
  queryKey: ["patientInsightsOverview"],
  queryFn: () =>
    getPatientInsightsOverview({
      program: Program.RPM,
      vital: VITAL.BP,
    }),
});

const handlePatientMonitorExit = () => {
  patientProfileKey.value = null;
  patientProfileProps.patientId = '';
  patientProfileProps.patientInfo = undefined;
};

const handlePatientSelection = async (eventData: PatientInsight) => {
  handlePatientMonitorExit();
  await loadPatientData(eventData);
};

const loadPatientData = async (eventData: PatientInsight) => {
  patientProfileLoading.value = true;
  const selectedPatientDetail = await getPatientMonitorDependencies.getPatientByPatientId([eventData.patientId]);
  patientProfileLoading.value = false;
  const patientInfo: IPatientInfo = {
    firstName: selectedPatientDetail.firstName,
    lastName: selectedPatientDetail.lastName,
    gender: GenderLabel[selectedPatientDetail.gender as Gender],
    dob: selectedPatientDetail.dob,
    patientId: selectedPatientDetail.patientId,
    isActive: selectedPatientDetail.isActive,
    isDeceased: selectedPatientDetail.isDeceased,
    createdTimestamp: selectedPatientDetail.createdTimestamp,
    providers: selectedPatientDetail.providers
  };
  patientProfileKey.value = selectedPatientDetail.patientId;
  patientProfileProps.patientId = selectedPatientDetail.patientId;
  patientProfileProps.patientInfo = patientInfo;
  patientProfileComponent.value = PatientMonitor;
}


const wellnessInsightsCount = computed(() => {
  const insights = programInsights.value;
  if (insights) {
    const { severity } = insights.programs.RPM.vitals.BP;
    return (severity.critical || 0) + (severity.moderate || 0) + (severity.normal || 0);
  } else {
    return 0;
  }

});

const inadherentCount = computed(() => {
  const insights = programInsights.value;
  if (insights) {
    const { compliance } = insights.programs.RPM.vitals.BP;
    return (compliance.veryLate || 0) + (compliance.late || 0);
  } else {
    return 0;
  }
});


const inReviewCount = computed(() => {
  const insights = programInsights.value;
  if (insights) {
    const { toReview } = programInsights.value.programs.RPM.vitals.BP;
    return toReview;
  } else {
    return 0;
  }
});

const handlePageSizeChange = (change: PAGE_SIZER) => {
  const PAGE_COLS = {
    [PAGE_SIZER.SPLIT_SCREEN]: () => { listCols.value = 5; monitorCols.value = 7; },
    [PAGE_SIZER.EXPAND_LEFT]: () => { listCols.value = 0; monitorCols.value = 12; },
    [PAGE_SIZER.EXPAND_RIGHT]: () => { listCols.value = 12; monitorCols.value = 0; },
    [PAGE_SIZER.CLOSE]: () => { listCols.value = 5; monitorCols.value = 7; handlePatientMonitorExit(); },
  }
  PAGE_COLS[change]();
}

onMounted(() => {
  patientProgramInsightsEvent.on(() => {
    queryClient.invalidateQueries({
      queryKey: ["patientInsightsOverview"],
    });
  })
});
</script>

<script lang="ts">
export enum PROGRAM_INSIGHTS_TABS {
  WELLNESS = 'WELLNESS',
  ADHERENCE = 'ADHERENCE',
  REVIEW = 'REVIEW',
}
</script>

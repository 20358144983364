<template>
  <Toaster position="bottom-left" :visibleToasts=5 richColors />
</template>

<script setup lang="ts">
import { defineProps, watch, ref, PropType } from 'vue';
import { Toaster, toast } from 'vue-sonner';
import ToastTemplate from './ToastTemplate.vue';
import { h } from 'vue';
import { IPatientReadingDetail } from '../interfaces/patientInsights.interface';
import { VitalsSeverity } from '../enums/patientInsights.enum';

const props = defineProps({
  patientDetail: {
    type: Object as PropType<IPatientReadingDetail>,
  },
});
const toastColor = ref('success');

const showReadingToast = (newValue: IPatientReadingDetail) => {
  const patientSeverity = newValue.patientSeverity;
  const isOutDated = newValue.isOutDated;
  toastColor.value = getToastColor(patientSeverity, isOutDated);

  toast(
    h(ToastTemplate, {
      patientDetail: newValue,
      toastColor: toastColor.value,
    }), {
    style: {
      backgroundColor: `rgba(var(--v-theme-${toastColor.value}),0.25) !important`,
      padding: 0,
      width: '350px',
    },
    closeButton: true,
    class: 'vital-info-toast',
    duration: import.meta.env.VITE_TOAST_VISIBLE_DURATION || 60000,
  });
};

const getToastColor = (severity: VitalsSeverity, isOutDated: boolean) => {
  if (isOutDated) {
    return 'info';
  }

  switch (severity) {
    case VitalsSeverity.HIGH:
      return 'error';
    case VitalsSeverity.MODERATE:
      return 'warning';
    case VitalsSeverity.NORMAL:
      return 'success';
    default:
      return 'warning';
  }
};

watch(() => props.patientDetail, (newValue) => {
  if (newValue) {
    showReadingToast(newValue);
  }
});
</script>

<style>
.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

[data-sonner-toast] [data-content] {
  display: flex;
  flex-direction: column;
  gap: 2px;
  transform: translateZ(0);
  width: 100%;
}
</style>

<template>
  <v-card flat color="surface" class="w-100 h-100" rounded="false0">
    <div class="d-flex flex-column">
      <v-tabs v-model="dataTab" color="primary" class="text-primary" density="compact">
        <v-tab :value="PATIENT_DETAILS.DEMOGRAPHICS">
          <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_DETAILS.DEMOGRAPHICS }} </span>
        </v-tab>
        <v-tab :value="PATIENT_DETAILS.PROVIDERS">
          <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_DETAILS.PROVIDERS }} </span>
        </v-tab>
        <v-tab :value="PATIENT_DETAILS.AUTHORIZED">
          <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_DETAILS.AUTHORIZED }} </span>
        </v-tab>
        <v-tab :value="PATIENT_DETAILS.BILLING_CONFIG">
          <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_DETAILS.BILLING_CONFIG }} </span>
        </v-tab>
      </v-tabs>
      <v-window v-model="dataTab">
        <v-window-item :value="PATIENT_DETAILS.DEMOGRAPHICS">
          <PatientDemographics :patient-profile="patientProfile"
            :get-patient-by-patient-id="getPatientDemographicsDependencies.getPatientByPatientId"
            :patient-status-change="getPatientDemographicsDependencies.updatePatient"
            :save-patient-event="getPatientDemographicsDependencies.savePatientEvent"
            :compact-view="compactView"  />
        </v-window-item>
        <v-window-item :value="PATIENT_DETAILS.PROVIDERS">
          <ProviderDetails :patient-profile="patientProfile"
            :get-patient-providers="getPatientDemographicsDependencies.getPatientProviders" />
        </v-window-item>
        <v-window-item :value="PATIENT_DETAILS.AUTHORIZED">
          <UserPatientLink :get-details-dependencies="getDetailsDependencies" :patient-id="patientProfile.patientId" />
        </v-window-item>
        <v-window-item :value="PATIENT_DETAILS.BILLING_CONFIG">
          <patient-cycle-billing :patient-id="patientProfile.patientId" :patient-sources-loader="patientSourcesLoader"
            :data-loader="getPatientDemographicsDependencies.getPatientBillingConfig"
            :data-persister="getPatientDemographicsDependencies.savePatientBillingConfig" />
        </v-window-item>
      </v-window>
    </div>
  </v-card>
</template>
<script setup lang="ts">
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { IPatientDetailsDependencies } from '@/interfaces/Service.interface';
import { PropType, ref } from 'vue';
import { PATIENT_DETAILS } from '../../../../enums/patient.enum'
import PatientDemographics from '../../../../components/views/patient/PatientDemographics.vue'
import { pick } from 'lodash';
import ProviderDetails from '../../../../components/views/patient/ProviderDetails.vue';
import PatientCycleBilling from '../reports/billing/PatientCycleBilling.vue';
import { ISource } from '@/interfaces/source.interface';
import UserPatientLink from '../../../views/patient/PE/UserPatientLink.vue';

const { getDetailsDependencies, patientProfile } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },

  getDetailsDependencies: {
    type: Object as PropType<IPatientDetailsDependencies>,
    required: true,
  },

  compactView: {
    type: Boolean,
    required: false,
    default: false
  },

  patientSourcesLoader: {
    type: Function as PropType<(patientId: string) => Promise<ISource[]>>,
    required: true,
  },
})

const getPatientDemographicsDependencies = pick(getDetailsDependencies, [
  'getPatientByPatientId',
  'getPatientProviders',
  'updatePatient',
  'getPatientBillingConfig',
  'savePatientBillingConfig',
  'savePatientEvent'
])

const dataTab = ref(PATIENT_DETAILS.DEMOGRAPHICS);
</script>

import {
  CREATE_PATIENT_DOCUMENT,
  DELETE_PATIENT_DOCUMENT,
  DOWNLOAD_FILE,
  FILE_UPLOAD_TOKEN,
  PATIENT_DOCUMENTS,
} from "@/graphql/resources/FileUplaodRepository";
import {
  DocumentFilter,
  DocumentInput,
  DocumentListResponse,
  IDeleteDocumentInput,
  IDocDownloadInput,
  IDocument,
} from "@/interfaces/document-upload.interface";
import { apolloClient } from "@/plugins/graphqlclient";

export const getFileUploadToken = async (
  patientId: string
): Promise<string> => {
  const response = await apolloClient.mutate({
    mutation: FILE_UPLOAD_TOKEN,
    variables: { patientId },
  });

  return response.data.getFileUploadToken;
};

export const createPatientDocument = async (
  documentInput: DocumentInput
): Promise<IDocument> => {
  const response = await apolloClient.mutate({
    mutation: CREATE_PATIENT_DOCUMENT,
    variables: { documentInput },
  });

  return response.data;
};

export const patientDocumentLists = async (
  patientId: string,
  filter: DocumentFilter
): Promise<DocumentListResponse> => {
  const response = await apolloClient.query({
    query: PATIENT_DOCUMENTS,
    variables: { patientId, filter },
    fetchPolicy: "network-only",
  });

  return response.data.patientDocuments;
};

export const deletePatientUploadedDocument = async (
  deleteDocumentInput: IDeleteDocumentInput
): Promise<boolean> => {
  const { documentId, patientId, documentKey, isSavedFile } =
    deleteDocumentInput;
  const response = await apolloClient.mutate({
    mutation: DELETE_PATIENT_DOCUMENT,
    variables: { patientId, documentId, documentKey, isSavedFile },
  });

  return response.data;
};

export const downloadUploadedFile = async (
  input: IDocDownloadInput
): Promise<string> => {
  const { documentId, patientId, documentKey } = input;
  const response = await apolloClient.query({
    query: DOWNLOAD_FILE,
    variables: { patientId, documentId, documentKey },
    fetchPolicy: "network-only",
  });

  return response.data.documentUrl;
};

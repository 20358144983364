<template>
  <v-card class="w-100 vital-config-card" flat color="surfContainer">
    <v-form @submit.prevent="saveConfiguration">
      <v-tabs v-model="selectedTab" color="primary" class="bg-surfContainerHigh text-primary" density="compact" grow>
        <v-tab :value="VITAL_CONFIGURATION_CRITERIA.BP">
          <span class="text-subtitle-1 font-weight-bold"> Blood Pressure</span>
        </v-tab>
      </v-tabs>
      <v-window v-model="selectedTab" >
        <v-window-item :value="VITAL_CONFIGURATION_CRITERIA.BP">
                        <BPRangeCriteria :vitalBPConfigurationData="vitalBPConfigurationData" :patientId="patientId"
                :vital-criteria-compact-view="vitalCriteriaCompactView"
                @update:range-selection="emittedMeasurementData"></BPRangeCriteria>
        </v-window-item>
      </v-window>
      <v-card-actions style="float: right;">
        <v-btn class="px-8" variant="elevated" rounded="false" type="submit" :disabled="isInValidRatio">Save
          Configuration</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script setup lang="ts">
import { ref, onMounted, PropType } from 'vue';
import { IVitalConfigure, IVitalThresholds } from '@/interfaces/IVitalConfigure';
import { get, set } from 'lodash';
import { VITAL_CONFIGURATION_CRITERIA } from '@/enums/vitals.enum';
import BPRangeCriteria from './config/bp/BPRangeCriteria.vue';
import { first, last } from 'lodash';

const props = defineProps({
  vitalBPConfigurationData: {
    type: Object as PropType<IVitalConfigure>,
    required: true,
  },
  patientId: {
    type: String,
    required: false
  },
  vitalCriteriaCompactView: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['emitted:vitalBPConfigurationData']);
const selectedTab = ref(VITAL_CONFIGURATION_CRITERIA.BP);
const isInValidRatio = ref(false);

const configureData = ref({
  config: {
    systolic: {
      thresholds: {
        criticalMin: 1,
        warnMin: 1,
        normalMin: 1,
        normalMax: 1,
        warnMax: 1,
        criticalMax: 1,
      },
      criteria: {
        critical: {
          measurementUnit: 'PERC_IN_RANGE',
          measurement: 5,
          timeWindow: 7,
        },
        warning: {
          measurementUnit: 'PERC_IN_RANGE',
          measurement: 3,
          timeWindow: 5,
        },
      }
    },
    diastolic: {
      thresholds: {
        criticalMin: 1,
        warnMin: 1,
        normalMin: 1,
        normalMax: 1,
        warnMax: 1,
        criticalMax: 1,
      },
      criteria: {
        critical: {
          measurementUnit: 'PERC_IN_RANGE',
          measurement: 5,
          timeWindow: 7,
        },
        warning: {
          measurementUnit: 'PERC_IN_RANGE',
          measurement: 3,
          timeWindow: 5,
        },
      }
    },
    ihr: {
      criteria: {
        critical: {
          measurementUnit: 'PERC_IN_RANGE',
          measurement: 1,
          timeWindow: 1,
        },
        warning: {
          measurementUnit: 'PERC_IN_RANGE',
          measurement: 1,
          timeWindow: 1,
        },
      }
    }
  },
  reportingFrequency: {
    late: {
      minReportingDays: 5,
      cutoffDays: 7,
    },
    veryLate: {
      minReportingDays: 5,
      cutoffDays: 7,
    },
  },
});

const setVitalData = () => {
  const configures = ["systolic", "diastolic", "ihr"];
  configures.forEach(prop => {
    set(configureData.value.config, `${prop}.thresholds`, get(props.vitalBPConfigurationData.config, `${prop}.thresholds`));
    set(configureData.value.config, `${prop}.criteria`, get(props.vitalBPConfigurationData.config, `${prop}.criteria`));
  });
  configureData.value.reportingFrequency = props.vitalBPConfigurationData.reportingFrequency;
}

const emittedMeasurementData = (bpReadingData: IVitalThresholds[]) => {
  configureData.value.config.systolic.thresholds = first(bpReadingData) as IVitalThresholds;
  configureData.value.config.diastolic.thresholds = last(bpReadingData) as IVitalThresholds;
};

function cleanVitalConfigurationData(configData: IVitalConfigure): any {
  if (Array.isArray(configData)) {
    return configData.map(cleanVitalConfigurationData);
  } else if (typeof configData === 'object' && configData !== null) {
    return Object.fromEntries(
      Object.entries(configData)
        .filter(([key]) => key !== '__typename')
        .map(([key, value]) => [key, cleanVitalConfigurationData(value)])
    );
  }
  return configData;
}


const saveConfiguration = () => {
  const dataWithoutTypename = cleanVitalConfigurationData(configureData.value);
  emit('emitted:vitalBPConfigurationData', dataWithoutTypename);
}

onMounted(() => {
  setVitalData();
})
</script>
<style scoped>
.config-container {
  min-height: 200px;
}

.vital-config-card {
  min-width: 200px;
  padding: 10px !important;
}
</style>

import gql from "graphql-tag";

export const GET_STAFF_ACTIVITY_REPORT = gql`
  query getOrgStaffActivityReport($orgId: UUID!, $cycle: ReportCycleInput!) {
    orgStaffActivityReport(orgId: $orgId, cycle: $cycle) {
      orgId
      cycleStart
      cycleDays
      cycleType
      loggedActivities {
        patientId
        activity {
          activityId
          orgId
          label
          description
          isActive
          isDefault
          variableDefs {
            key
            required
            parseAs
          }
        }
        startTime
        endTime
        timeSpent
        loggedBy {
          orgUserId
          userId
          firstName
          lastName
          emailAddress
          mobilePhoneNumber
          homePhoneNumber
          npi
          deaNumber
          designation
          organizationId
          roles
        }
        isAutoLogged
        firstName
        lastName
        gender
        dob
        isActive
        isDeceased
        provider {
          userId
          orgId
          firstName
          lastName
          mobilePhone
          email
          npi
          active
          isPrimaryProvider
        }
        createdTimestamp
      }
    }
  }
`;

export const GET_PATIENT_BILLING_REPORT = gql`
  query getOrgPatientBillingReport(
    $orgId: UUID!
    $cycle: ReportCycleInput!
    $filters: BillingFilter!
  ) {
    orgPatientBillingReport(orgId: $orgId, cycle: $cycle, filters: $filters) {
      orgId
      cycleStart
      cycleDays
      cycleType
      programCodes
      patientBillingsInCycle {
        id
        patientId
        programsInCycle {
          id
          patientId
          patientProgramId
          programStartDate
          cycleStart
          cycleDays
          isCurrentCycle
          cycleType
          cycleStatus {
            daysActive
            daysMissed
            daysRemaining
            activityHistory {
              date
              activity {
                vital
                dataPointId
              }
            }
            firstMeasurementDate
          }
          program
        }
        programsSelectedForBilling {
          id
          patientId
          patientProgramId
          cycleStart
          cycleDays
          isCurrentCycle
          cycleType
          cycleStatus {
            daysActive
            daysMissed
            daysRemaining
            activityHistory {
              date
              activity {
                vital
                dataPointId
              }
            }
            firstMeasurementDate
          }
          program
        }
        billingDistributions {
          cptCode {
            code
            bucket
          }
          ccmBucket {
            timeInCycle
            billingCount
            revenue
            billable
          }
          cccmBucket {
            timeInCycle
            billingCount
            revenue
            billable
          }
          pcmBucket {
            timeInCycle
            billingCount
            revenue
            billable
          }
          rpmBucket {
            timeInCycle
            billingCount
            revenue
            billable
          }
        }
        firstName
        lastName
        dob
        isActive
        isDeceased
        provider {
          userId
          orgId
          firstName
          lastName
          mobilePhone
          email
          npi
          active
          isPrimaryProvider
        }
        createdTimestamp
        additionalBillingInfo
        timeSpent {
          qhcp
          staff
        }
        isCustomized
      }
    }
  }
`;

<template>
  <v-sheet class="py-4">
    <v-card color="surfContainer pa-2" flat>
      <v-card-title class="d-flex align-center text-primary bg-surface rounded-lg">
        <div class="d-flex align-center items-center text-primary">
          <v-icon size="large">mdi-file-upload-outline</v-icon>
          <div class="d-flex flex-wrap">
            <span class="ml-4">Upload Documents</span>
            <span class="v-card-subtitle flex-1-1-100"> Upload patient related documents</span>
          </div>
        </div>
        <v-spacer />
        <div class="d-flex justify-end">
          <v-btn elevation="3" @click="openFileUpload = true" rounded="false"> Upload</v-btn>
        </div>
      </v-card-title>
      <v-card-item>
        <v-data-table :loading="loading" v-model:items-per-page="itemsPerPage" :headers="headers" :items="documents"
          :items-length="totalItems" item-value="name">
          <template v-slot:[`item.action`]="{ item }">
            <div style="min-width: 120px;" class="action-buttons">
              <v-btn dense class="text-none mx-2" @click="downloadingUploadedFile(item)" size="small" rounded
                variant="tonal" icon="mdi-download" />
              <v-btn dense class="text-none mx-2" @click="deletePatientDocument(item, true)" size="small" rounded
                variant="tonal" icon="mdi-delete" />
            </div>
          </template></v-data-table>
      </v-card-item>
    </v-card>
    <v-dialog width="1100" v-model="openFileUpload" persistent>
      <file-uploader :patient-id="patientId" :get-file-upload-token="getFileUploadToken" @close="openFileUpload = false"
        :create-patient-document="createPatientDocument" @delete-upload-file="deleteFileFromRemoteStorage"
        :isFileDeleting="isFileDeleting" @delete-uploaded-files="deleteFilesFromRemoteStorage"></file-uploader>
    </v-dialog>
  </v-sheet>
  <v-snackbar color="success" class="text-white" v-model="isSuccessMessage" location="top right">
    {{ successMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="isErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="error" class="text-white" v-model="isErrorMessage" location="top right">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="isErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<script setup lang="ts">
import { PropType, ref } from 'vue';
import FileUploader from './FileUploader.vue';
import { DocumentFilter, DocumentInput, DocumentListResponse, IDeleteDocumentInput, IDocDownloadInput, IDocument, IDocumentList, IUploadFile } from '@/interfaces/document-upload.interface';
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import moment from 'moment';
import { ActionStatus } from '@/enums/document-upload.enum';
import { isEmpty } from 'lodash';

const props = defineProps({
  getFileUploadToken: {
    type: Function as PropType<(patientId: string) => Promise<string>>,
    required: true,
  },
  patientId: {
    type: String,
    required: true,
  },
  createPatientDocument: {
    type: Function as PropType<(documentInput: DocumentInput) => Promise<IDocument>>,
    required: true,
  },
  patientDocumentLists: {
    type: Function as PropType<(patientId: string, filter: DocumentFilter,) => Promise<DocumentListResponse>>,
    required: true,
  },
  deletePatientUploadedDocument: {
    type: Function as PropType<(deleteDocumentInput: IDeleteDocumentInput) => Promise<boolean>>,
    required: true,
  },
  downloadFile: {
    type: Function as PropType<(input: IDocDownloadInput) => Promise<string>>,
    required: true,
  }
});

const { patientId, patientDocumentLists, downloadFile } = props;

const itemsPerPage = ref(10);
const totalItems = ref(0);
const openFileUpload = ref(false);

const loading = ref(false);
const isErrorMessage = ref(false);
const errorMessage = ref('');
const isSuccessMessage = ref(false);
const successMessage = ref('');
const queryClient = useQueryClient();
const isFileDeleting = ref(false);

const headers = ref([
  { title: "Document Name", key: "documentKey" },
  { title: "Document Title", key: "title" },
  { title: "Document Type", key: "type" },
  { title: "Uploaded By", key: "userName" },
  { title: "Uploaded At", key: "createdAt" },
  { title: "Action", key: "action" }
]);

const documents = ref([] as IDocumentList[]);

const getPatientDocuments = (): Promise<IDocumentList[]> => {
  loading.value = true;
  const filter: DocumentFilter = {
    limit: 0,
    offset: 0,
  };
  return patientDocumentLists(patientId, filter)
    .then((data) => {
      const { documentData = [] } = data;

      if (isEmpty(documentData)) {
        documents.value = []
        return [];
      }

      const documentsData = !isEmpty(documentData) ? transformDocumentData(documentData) : [];
      documents.value = documentsData;
      totalItems.value = data?.totalCount || 0;

      return documents.value;
    })
    .catch((error) => {
      isErrorMessage.value = true;
      errorMessage.value = (error as Error).message || 'An error occurred';
      return [];
    }).finally(() => {
      loading.value = false;
    });
};

const transformDocumentData = (documentData: IDocument[]): IDocumentList[] => {
  const transformedData = documentData.map(({ metaData, createdAt, ...document }) => {
    const docMetaData = metaData.find((data) => data.action === ActionStatus.UPLOAD)!;
    const { firstName, lastName } = docMetaData.orgUser;
    const documentDetails = {
      ...document,
      userName: `${firstName} ${lastName}`.trim(),
      createdAt: createdAt ? moment(Number(createdAt)).format('MM/DD/YYYY hh:mm a') : 'N/A',
    };
    return documentDetails;
  });

  return transformedData;
};

const { data: docsResponse, isLoading } = useQuery({
  queryKey: ['patientDocuments'],
  queryFn: () => getPatientDocuments(),
});

const deletePatientDocument = async (documentData: { documentId: string; documentKey: string }, isSavedFile: boolean) => {
  loading.value = true;

  const { documentId, documentKey, } = documentData;

  const DocDeleteData: IDeleteDocumentInput = { patientId, documentId, documentKey, isSavedFile }

  await props.deletePatientUploadedDocument(DocDeleteData).then((response) => {
    isSuccessMessage.value = isSavedFile;
    successMessage.value = 'Document deleted successfully';
    queryClient.invalidateQueries({ queryKey: ['patientDocuments'] })
    return response;
  }).catch((error) => {
    isErrorMessage.value = true;
    errorMessage.value = (error as Error).message;
  }).finally(() => {
    loading.value = false;
  });
}

const deleteFileFromRemoteStorage = async (s3UploadFiles: IUploadFile[]) => {
  try {
    await Promise.all(
      s3UploadFiles.map(file =>
        deletePatientDocument({ documentId: file.documentId, documentKey: file.name }, false)
      )
    );
  } catch (error) {
    isErrorMessage.value = true;
    errorMessage.value = (error as Error).message;
  }
};

const deleteFilesFromRemoteStorage = async (s3UploadFiles: IUploadFile[]) => {
  isFileDeleting.value = true;
  try {
    await Promise.all(
      s3UploadFiles.map(file =>
        deletePatientDocument({ documentId: file.documentId, documentKey: file.name }, false)
      )
    );
    openFileUpload.value = false;
    isFileDeleting.value = false;
  } catch (error) {
    isErrorMessage.value = true;
    errorMessage.value = (error as Error).message;
    isFileDeleting.value = false;
  }
};

const downloadingUploadedFile = (documentData: IDocumentList) => {
  const { documentId, documentKey } = documentData;
  downloadFile({ patientId, documentId, documentKey })
    .then((response) => {

      fetch(response)
        .then(res => res.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.setAttribute('download', documentKey);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(url);
        });
    })
    .catch((error) => {
      isErrorMessage.value = true;
      errorMessage.value = (error as Error).message;
    })
}
</script>

export enum PATIENT_PRIORITY_GROUPS {
    HIGH_SEV,
    MOD_SEV,
    NORM_SEV,
    LATE_REPORT,
    VERY_LATE_REPORT,
    NOT_REVIEWED,
}

export enum PATIENT_DATA_CATEGORIES {
    VITALS,
    DETAILS,
    COMPLIANCE,
    ACTIONS,
    INTEGRATIONS,
    CRITERIA,
    SUBSCRIPTIONS,
    UPLOADS
}

export enum PATIENT_VITALS {
    BP="Blood Pressure",
}

export enum PATIENT_DETAILS {
    DEMOGRAPHICS="Demographics",
    PROVIDERS="Providers",
    AUTHORIZED = "Linked Contacts",
    BILLING_CONFIG="Billing Configuration",
}

export enum PATIENT_CONFIGS {
    VITALS = "Vitals Config",
    DETAILS = "Details Config",
    COMPLIANCE = "Adherence Config",
    ACTIONS = "Actions Config",
    INTEGRATIONS = "Integrations Config",
}

export enum PATIENT_ACTIONS {
    TASKS = "Tasks",
    NOTES = "Notes",
    TIME_LOG = "Time Log",
}

export enum PATIENT_COMPLIANCE {
    CURRENT = "Current Cycle",
    ALL_TIME = "All Time",
}

export enum PATIENT_INTEGRATIONS {
    RPM = "RPM",
}

export enum PATIENT_STATUS {
    ACTIVATE = 'Activate',
    DEACTIVATE = 'Deactivate',
    DECEASED = 'Deceased'
}

export enum EXTERNAL_IDENTITIES {
   EMR_ID = 'EMR_ID',
}

export enum EXTERNAL_IDENTITY_LABELS {
   EMR_ID = 'EMR Id'
}

export enum Relationship {
  Child = 'CHILD',
  CARE_GIVER = 'CARE_GIVER',
  LIFE_PARTNER = 'LIFE_PARTNER',
  MOTHER = 'MOTHER',
  PARENT = 'PARENT',
  SELF = 'SELF',
  SIBLING = 'SIBLING',
  SISTER = 'SISTER',
  SPOUSE = 'SPOUSE',
  BROTHER = 'BROTHER',
  FATHER = 'FATHER',
  EXTENDED_FAMILY = 'EXTENDED_FAMILY',
  FOSTER_CHILD = 'FOSTER_CHILD',
  GRAND_CHILD = 'GRAND_CHILD',
  GRAND_PARENT = 'GRAND_PARENT',
  GUARDIAN = 'GUARDIAN',
  STEP_CHILD = 'STEP_CHILD',
  WARD_OF_COURT = 'WARD_OF_COURT',
  NATURAL_CHILD = 'NATURAL_CHILD',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

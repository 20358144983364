<template>
  <v-card :class="$vuetify.display.mdAndUp ? 'device-add-form mx-auto' : ''">
    <v-card-title class="text-center py-5">
      <span class="text-h6 text-primary font-weight-bold"> {{ profilePatientId ? 'Assign Device' : 'Assign Patient'
        }}</span>
    </v-card-title>
    <v-container>
      <v-form ref="patientAssociationForm" @submit.prevent="associatePatient" class="px-4">
        <v-row>
          <template
            v-if="(shortCode === DeviceShortCode.TENOVI || selectedServiceProviderShortCode === DeviceShortCode.TENOVI) && deviceMetaData">
            <v-col cols="12" class="mb-4">
              <v-row>
                <v-col cols="4">
                  <div>
                    <span class="mr-2">Gateway ID</span>
                    <v-chip color="primary">
                      {{ (deviceMetaData as ITenoviMeta).device.hardwareUuid || 'N/A' }}
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="5">
                  <div>
                    <span class="mr-2">Device Type</span>
                    <v-chip color="primary">
                      {{ profilePatientId ? inventoryDeviceType : props.deviceType }}
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="3">
                  <div>
                    <span class="mr-2">Sensor Code </span>
                    <v-chip color="primary">
                      {{ (deviceMetaData as ITenoviMeta).device.sensorCode || 'N/A' }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </template>
          <v-col cols="12">
            <v-card-title class="text-subtitle-1 ml-n5">Subscribed Programs : <v-chip
                v-if="isEmpty(programsAvailableForBilling)" color="primary">N/A</v-chip> <v-chip v-else
                v-for="(program, index) in programsAvailableForBilling" :key="index" class="ma-2" color="primary">
                {{ program.title }}
              </v-chip></v-card-title>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-select variant="outlined" v-if="profilePatientId" color="primary" :items="serviceProvidersInput"
              v-model="serviceProviderId" class="service-provider-field" label="Service Provider*" required></v-select>
            <v-combobox color="primary" v-else v-model="selectedPatient" required variant="outlined" dense
              :label="selectedPatientInput?.label" :hint="selectedPatientInput?.description" :items="patients"
              @update:search="loadFilteredPatients" item-title="label" item-value="value"
              :rules="[(v) => !!v || 'Patient is required', isValidPatient || 'Patient is not found']"
              :loading="patientsLoading" />
          </v-col>
          <v-col cols="6" v-if="!profilePatientId">
            <div class="mt-3">
              <span class="mr-2">Device Name</span>
              <v-chip color="primary">
                {{ deviceMetaData?.name || 'N/A' }}
              </v-chip>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6" v-if="profilePatientId">
            <v-combobox :disabled="!serviceProviderId" color="primary" v-model="searchDeviceId" required
              variant="outlined" dense label="Gateway ID*" hint="Enter gateway id" :items="gatewayIds"
              @update:search="listDevicesGatewayIdMatched()" item-title="title" item-value="value"
              :rules="[(v) => !!v || 'Gateway ID is required', isDevicesExist || 'Gateway ID not found']"
              :return-object="false" :loading="gatewayIdLoading" @change="listDevices()" />
          </v-col>
          <v-col cols="12" sm="6" md="6" v-if="profilePatientId">
            <v-select v-model="deviceId" :items="devices" variant="outlined" color="primary"
              :label="`${deviceInput?.label}*`" :loading="devicesLoading" :hint="deviceInput?.description"
              :rules="[(v) => !!v || 'Device is required']" required @update:model-value="updateDeviceInfo()"><template
                v-slot:no-data>
                <v-list-item>
                  <div>No devices available for assigning</div>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-if="shortCode === DeviceShortCode.QARDIO || (profilePatientId && selectedServiceProviderShortCode === DeviceShortCode.QARDIO)"
              :label="`Patient's ${serviceProviderName} ID*`" variant="outlined" color="primary"
              v-model="patientIdentityAtSource"
              :rules="[(v) => !!v || `Patient's ${serviceProviderName} id is required`]" required></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions class="pt-3">
          <v-row justify="end">
            <v-col sm="12" md="3" class="text-right">
              <v-btn class="text-white px-10 mr-n5" @click="cancelAdding" rounded="false" variant="tonal" elevation="3">
                Cancel
              </v-btn>
            </v-col>
            <v-col sm="12" md="3" class="text-right">
              <v-btn color="primary" class="px-10" type="submit" :rounded="false" elevation="3"
                :disabled="isFormFieldEmpty || isSubmitting" variant="elevated">
                Assign
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-container>
    <v-snackbar v-model="showErrorMessage" color="error" location="top right" class="mt-16">{{ errorMessage}}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script setup lang="ts">
import {
  IDropdownInput, ISourceList, ISource,
IListSourceInput
} from "@/interfaces/source.interface";
import { IRequestorInput, IRequestorItem } from "@/interfaces/task.interface";
import { get, identity, isEmpty, isNil, map, pickBy, first, debounce } from "lodash";
import { ref, PropType, onMounted, computed, watch } from "vue";
import { DeviceShortCode } from "@/enums/serviceprovider.enum";
import { IQardioMeta, ITenoviMeta } from "@/interfaces/InventoryItem.interface";
import { IPatientBillingCycle } from "@/interfaces/billing.interface";
import { Program } from "@/enums/patient-program.enum";
import { IPatientProgramCycle } from "@/interfaces/patient-program-cycle.interface";
import { BillingDistributionType } from "@/enums/patient-program-cycle.enum";

const selectedPatient = ref<IRequestorItem>();
const patientIdentityAtSource = ref("");
const patientsLoading = ref(false);
const serviceProviderId = ref("");
const devices = ref([] as IDropdownInput[]);
const deviceId = ref(null as null | string);
const devicesLoading = ref(false);
const gatewayIdLoading = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref('');
const isSubmitting = ref(false);
const selectedServiceProviderShortCode = ref(null as null | string);
const serviceProviderName = ref("");
const patientAssociationForm = ref();
const searchDeviceId = ref('');
const gatewayIds = ref([] as IDropdownInput[]);

const props = defineProps({
  deviceInput: {
    type: Object as PropType<ISourceList>,
    required: false,
  },
  profilePatientId: {
    type: String,
    required: false,
  },
  serviceProvidersInput: {
    type: Array as () => IDropdownInput[],
    required: false,
  },
  selectedPatientInput: {
    type: Object as PropType<IRequestorInput>,
    required: false,
  },
  inventoryMetaData: {
    type: Object as PropType<ITenoviMeta | IQardioMeta>,
    required: false,
  },
  shortCode: {
    type: String,
    required: false,
  },
  deviceType: {
    type: String,
    required: false,
  },
  programSubscribedDataLoader: {
    type: Function as PropType<(patientId: string, billingDistribution: BillingDistributionType) => Promise<IPatientBillingCycle>>,
    required: true
  },
});

const { programSubscribedDataLoader } = props;

const patients = ref<IRequestorItem[]>([]);
const deviceMetaData = ref<ITenoviMeta | IQardioMeta | null>(null);
const sources = ref([] as ISource[]);
const inventoryDeviceType = ref('');
const isDevicesExist = ref(true);
const programsAvailableForBilling = ref<{ title: Program, value: IPatientProgramCycle, props?: { disabled?: Boolean } }[]>([]);


const emit = defineEmits(["assignDevice", "cancelAssignation", "assignDeviceFromPatientProfile", "getPatientDetail"]);

const isValidPatient = computed(() => {
  return !isEmpty(patients.value);
});

const isFormFieldEmpty = computed(() => {
  if (props.profilePatientId) {
    return !deviceId.value || (!patientIdentityAtSource.value && selectedServiceProviderShortCode.value === DeviceShortCode.QARDIO);
  } else {
    return !selectedPatient.value?.value?.id || (!patientIdentityAtSource.value && props.shortCode === DeviceShortCode.QARDIO);
  }
});

const associatePatient = async () => {
  isSubmitting.value = true;
  const patientId = get(selectedPatient, 'value.value.id');
  const patientIdentity = get(patientIdentityAtSource, 'value');
  const selectedDeviceId = get(deviceId, 'value');
  if (!isNil(props.profilePatientId)) {
    const patientProfileDetails = pickBy({
      patientId: props.profilePatientId,
      deviceId: selectedDeviceId,
      patientIdentityAtSource: selectedServiceProviderShortCode.value === DeviceShortCode.QARDIO ? patientIdentity : props.profilePatientId,
    }, identity);
    emit("assignDeviceFromPatientProfile", patientProfileDetails);
  } else if (!isEmpty(patientId) && (!isEmpty(patientIdentity) || props.shortCode == DeviceShortCode.TENOVI)) {
    const patientDetails = pickBy({
      patientId: patientId,
      patientIdentityAtSource: props.shortCode === DeviceShortCode.QARDIO ? patientIdentity : patientId,
    }, identity);
    emit("assignDevice", patientDetails);
  }
  isSubmitting.value = false;
};

const updateDeviceInfo = () => {
  deviceMetaData.value = first(sources.value.filter(source => source.sourceId === deviceId.value))?.sourceMeta as ITenoviMeta | IQardioMeta;
  inventoryDeviceType.value = first(sources.value.filter(source => source.sourceId === deviceId.value))?.inventoryItemType.type!;
}

const cancelAdding = () => {
  emit("cancelAssignation");
};

onMounted(() => {
  deviceMetaData.value = props.inventoryMetaData as ITenoviMeta | IQardioMeta;
});


const loadFilteredPatients = debounce(async (search: string) => {
  if (props && props.selectedPatientInput) {
    patientsLoading.value = true;
    try {
      patients.value = search
        ? await props.selectedPatientInput.dataLoader(search)
        : [];
      patientsLoading.value = false;
      patientAssociationForm.value.validate();
    } catch (error) {
      patientsLoading.value = false;
      const { message } = error as Error;
      errorMessage.value = message;
      showErrorMessage.value = true;
    }
  }
}, 300);

const listDevicesGatewayIdMatched = debounce(async () => {
  await listDevicesByDeviceId(searchDeviceId.value);
}, 500);

const fetchData = async (input: IListSourceInput) => {
  if (props && props.deviceInput) {
    const devicesResponse = await props.deviceInput.dataLoader(input, false);
    return devicesResponse;
  }
  return [];
};

const updateValues = (matchedProvider: IDropdownInput | undefined) => {
  selectedServiceProviderShortCode.value = matchedProvider?.shortCode || '';
  serviceProviderName.value = matchedProvider?.title || '';
  deviceId.value = null;
  deviceMetaData.value = null;
};

const listDevices = async () => {
  if (searchDeviceId.value) {
    const matchedProvider = props.serviceProvidersInput?.find(provider => provider.value === serviceProviderId.value);
    updateValues(matchedProvider);

    const input = {
      serviceProviderId: serviceProviderId.value,
      isActive: true,
      isAssociated: false,
      filter: {
        deviceId: searchDeviceId.value,
        limit: 10,
        offset: 0,
      }
    };

    devicesLoading.value = true;
    const devicesResponse = await fetchData(input);
    devicesLoading.value = false;

    devices.value = map(devicesResponse, (device) => ({
      title: String(device.sourceMeta?.name ?? "N/A"),
      value: device.sourceId,
    }));
    sources.value = devicesResponse;
  }
};

const listDevicesByDeviceId = async (deviceId: string) => {
  if (deviceId) {
    const { serviceProvidersInput } = props;

    const matchedProvider = serviceProvidersInput?.find(provider => provider.value === serviceProviderId.value);
    updateValues(matchedProvider);

    const input = {
      serviceProviderId: serviceProviderId.value,
      isActive: true,
      isAssociated: false,
      filter: {
        deviceId,
        limit: 10,
        offset: 0,
      },
    };

    gatewayIdLoading.value = true;
    const devicesResponse = await fetchData(input);
    gatewayIdLoading.value = false;

    gatewayIds.value = devicesResponse.map(device => ({
      title: ((device.sourceMeta as ITenoviMeta)?.device.hardwareUuid) || '',
      value: ((device.sourceMeta as ITenoviMeta)?.device.hardwareUuid) || '',
    }));

    isDevicesExist.value = isEmpty(gatewayIds.value) ? false : true;
    patientAssociationForm.value.validate();
  }
};

watch(() => selectedPatient.value, () => {
  if(selectedPatient.value?.value){
  emit('getPatientDetail', selectedPatient.value?.value.id);
  getSubscribedPrograms();
}
})

const getSubscribedPrograms = () => {

    programSubscribedDataLoader(selectedPatient.value?.value.id!, BillingDistributionType.STANDARDIZED).then((data) => {

      if(data){
        programsAvailableForBilling.value = map(data.programsInCycle, prg => {
            return {
                title: prg.program,
                value: prg
            }
        });
      }
    }).catch((e) => {
        showErrorMessage.value = true;
        errorMessage.value = (e as Error).message;
    });
}

</script>
<style scoped>
.device-add-form {
  width: 800px;
}
</style>

export enum ActionStatus {
  UPLOAD = "UPLOAD",
  DELETE = "DELETE",
}

export enum DocumentType {
  REPORT = "REPORT",
  CONSULTATION = "CONSULTATION",
  PRESCRIPTION = "PRESCRIPTION",
  OTHER = "OTHER",
}

export enum FileUploadingStatus {
  UPLOADING = "uploading",
  COMPLETED = "completed",
  FAILED = "failed",
  ABORTED= "Aborted"
}

export enum FileTypes {
  JPG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  VIDEO_MP4 = 'video/mp4',
  VIDEO_WEBM = 'video/webm',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  CSV = 'text/csv',
  TEXT = 'text/plain',
}

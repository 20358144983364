<template>
    <v-card flat color="surfContainer" class="w-100 h-100">
        <div class="d-flex flex-column">
            <v-tabs color="primary" class="bg-surface text-primary" density="compact">
                <v-tab :value="PATIENT_VITALS.BP">
                    <span class="text-subtitle-2 font-weight-bold"> {{ PATIENT_VITALS.BP }} </span>
                </v-tab>
            </v-tabs>
            <v-window v-model="dataTab">
                <v-window-item :value="PATIENT_VITALS.BP">
                    <PatientBPView :patient-id="patientProfile.patientId"
                        :patient-data-source-input="getPatientDataDependencies.listPatientSources"
                        :data-loader="getPatientDataDependencies.getPatientReadings"
                        :look-back-range-input="getPatientDataDependencies.getLookBackRanges"
                        :patientVitalConfigLoader="getPatientDataDependencies.getPatientVitalsThresholdConfig"
                       />
                </v-window-item>
            </v-window>
        </div>
    </v-card>
</template>
<script setup lang="ts">
import { PATIENT_VITALS } from '../../../../enums/patient.enum';
import { ref } from 'vue';
import PatientBPView from './bp/PatientBPView.vue';
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { PropType } from 'vue';
import { IPatientMonitor } from '@/interfaces/Service.interface';
const dataTab = ref(PATIENT_VITALS.BP);

const { getPatientDataDependencies, patientProfile } = defineProps({
    patientProfile: {
        type: Object as PropType<IPatientProfileProps>,
        required: true
    },
    getPatientDataDependencies: {
        type: Object as PropType<IPatientMonitor>,
        required: true
    }
})

</script>

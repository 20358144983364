<template>
  <v-card :color="toastColor" class="pa-2 ma-0" variant="flat">
    <v-card-item>
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col cols="6" class="d-flex justify-center align-center flex-col">
            <div style="white-space: nowrap; overflow-x: clip;">
              <span class="text-subtitle-1 font-weight-black text-center">
                {{
                  `${patientDetail.firstName}
                ${patientDetail.lastName}` }}
              </span>
            </div>
            <div class="text-subtitle-2 text-center">
              <span>
                {{ age(patientDetail.dob!) }} years, {{ patientDetail.gender
                }}
              </span>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex justify-center align-center flex-col">
            <div>
              <span class="text-h6 font-weight-black text-center">{{
                patientDetail.systolicValue }}/{{
                  patientDetail.diastolicValue }}
              </span>
            </div>
            <div>
              <span class="text-subtitle-2"> Blood Pressure </span>
            </div>
          </v-col>
          <v-col cols="2" class="d-flex justify-end align-center">
            <router-link :to="{
              name: 'patientProfileWithInfo',
              params: {
                patientId: patientDetail.patientId,
                patient: encodePatientInfo(patientEncodeDetails),
              },
              query: {
                name: 'PatientConsents'
              }
            }">
              <v-btn rounded size="x-small" :color="toastColor" variant="flat" icon="mdi-account-eye"
                elevation="3"></v-btn>
            </router-link>
          </v-col>
        </v-row>
        <div class="pt-3" v-if="patientDetail.isOutDated">
          <v-sheet rounded class="text-caption" color="surfContainerHighest">
            <v-icon left class="mr-2" color="info">mdi-information</v-icon>
            This reading is outdated as it was captured at {{ new
              Date(patientDetail.dataPoint.collectedTimestamp).toLocaleDateString() }} {{ new
              Date(patientDetail.dataPoint.collectedTimestamp).toLocaleTimeString() }}. Hence, it is not classified.
          </v-sheet>
        </div>
      </v-container>
    </v-card-item>
  </v-card>
</template>
<script lang="ts" setup>
import { ref, PropType } from 'vue';
import { age } from '../helpers/date.helpers';
import { encodePatientInfo } from '../composables/patient/view-utility.composable';
import { IPatientInfo } from '../interfaces/patient.interface';
import { IPatientReadingDetail } from '../interfaces/patientInsights.interface';

const props = defineProps({
  patientDetail: {
    type: Object as PropType<IPatientReadingDetail>,
    required: true,
  },
  toastColor: {
    type: String,
    required: true,
  }
});

const patientEncodeDetails = ref({
  firstName: props.patientDetail.firstName,
  lastName: props.patientDetail.lastName,
  dob: props.patientDetail.dob,
  patientId: props.patientDetail.patientId
} as IPatientInfo);


</script>

<style scoped>
.custom-chip {
  white-space: normal;
  min-height: 60px;
}
</style>

import gql from "graphql-tag";

export const PATIENT_PROGRAM_CONSENT_LIST = gql`
  query patientProgramConsents($patientId: String) {
    patientProgramConsents(patientId: $patientId) {
      id
      orgId
      programId
      programName
      parentProgramId
      isDefault
      isActive
      patientProgramId
      isSubscribed
      shortCode
      consent {
        id
        consentDownloadUrl
        signedAt
        signedBy
        status
        createdAt
        modifiedAt
        eConsentTemplateId
      }
    }
  }
`;

export const ALL_PATIENT_CONSENT_LIST = gql`
  query getAllPatientConsents(
    $limit: Int
    $offset: Int
    $filter: patientConsentFilter
  ) {
    getAllPatientConsents(limit: $limit, offset: $offset, filter: $filter) {
      patientConsents {
        patient {
          firstName
          lastName
          patientId
          gender
          dob
          providers {
            firstName
            lastName
            isPrimaryProvider
          }
          email
          isActive
          isDeceased
          createdTimestamp
        }
        patientProgramsConsents {
          id
          orgId
          programId
          programName
          shortCode
          parentProgramId
          isDefault
          isActive
          isSubscribed
          patientProgramId
          consent {
            id
            consentDownloadUrl
            signedAt
            signedBy
            status
            eConsentTemplateId
            createdAt
            modifiedAt
          }
        }
      }
      count
    }
  }
`;

export const SUBSCRIBE_PROGRAM = gql`
  mutation patientProgramConsents(
    $subscribeProgramInput: SubscribeProgramInput
  ) {
    subscribePrograms(subscribeProgramInput: $subscribeProgramInput) {
      id
      orgId
      patientId
      programId
      cycleType
      programEnrollmentDate
      isActive
    }
  }
`;

export const GET_CONSENT_TEMPLATE = gql`
  query getProgramEConsentTemplate(
    $econsentTemplateId: String
    $templateParamsInput: EConsentTemplateParamJSON
  ) {
    getProgramEConsentTemplate(
      econsentTemplateId: $econsentTemplateId
      templateParamsInput: $templateParamsInput
    ) {
      id
      programId
      orgId
      template
      active
      createdAt
      modifiedAt
      version
      associatedProgramIds
      programs {
        id
        orgId
        shortCode
        label
        parentProgramId
        isDefault
      }
    }
  }
`;

export const GET_CONSENT_UPLOAD_URL = gql`
  query getProgramEConsentUploadUrl($patientId: String, $fileName: String) {
    getProgramEConsentUploadUrl(patientId: $patientId, fileName: $fileName)
  }
`;

export const GET_CONSENT_DOWNLOAD_URL = gql`
  query getProgramEConsentDownloadUrl($eConsentId: String) {
    getProgramEConsentDownloadUrl(eConsentId: $eConsentId)
  }
`;

export const SIGN_CONSENT = gql`
  mutation signPatientProgramConsent(
    $patientId: String
    $eConsentTemplateId: String
    $eConsentFileName: String
    $selectedProgramId: String
    $acknowledgment: EConsentAcknowledgement!
  ) {
    signPatientProgramConsent(
      patientId: $patientId
      eConsentTemplateId: $eConsentTemplateId
      eConsentFileName: $eConsentFileName
      selectedProgramId: $selectedProgramId
      acknowledgment: $acknowledgment
    ) {
      id
      orgId
      patientId
      programId
      cycleType
      programEnrollmentDate
      isActive
    }
  }
`;

export const SUBSCRIBE_PROGRAMS = gql`
  mutation subscribePrograms($subscribeProgramInput: SubscribeProgramInput) {
    subscribePrograms(subscribeProgramInput: $subscribeProgramInput) {
      id
      orgId
      patientId
      programId
      cycleType
      programEnrollmentDate
      isActive
    }
  }
`;

export const UN_SUBSCRIBE_PROGRAM = gql`
  mutation unSubscribeProgram(
    $unSubscribeProgramInput: UnSubscribeProgramInput
  ) {
    unSubscribeProgram(unSubscribeProgramInput: $unSubscribeProgramInput)
  }
`;

export const RESET_PATIENT_PROGRAM_TEMPLATE = gql`
  mutation resetPatientProgramConsent(
    $resetPatientProgramConsentInput: ResetPatientProgramConsentInput
  ) {
    resetPatientProgramConsent(
      resetPatientProgramConsentInput: $resetPatientProgramConsentInput
    )
  }
`;

export const SUBSCRIBE_PROGRAM_USING_SHORT_CODE = gql`
  mutation subscribeProgramUsingShortCode(
    $subscribeProgramInput: SubscribeProgramUsingShortCodeInput
  ) {
    subscribeProgramUsingShortCode(
      subscribeProgramInput: $subscribeProgramInput
    ) {
      id
      orgId
      patientId
      programId
      cycleType
      programEnrollmentDate
      isActive
      isSubscribed
    }
  }
`;

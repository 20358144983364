<template>
  <v-card variant="flat" color="surface" elevation="2">
    <v-skeleton-loader v-if="loading" type="avatar, text, button@2 " />
    <v-container v-show="!loading" fluid class="pa-1">
      <v-row>
        <v-col cols="3" class="d-flex align-center justify-space-around">
          <div class="d-flex align-center">
            <v-avatar rounded :color="computedAvatarColor" class="text-h6 mx-2 font-weight-black">
              <h5 class="text-h5 font-weight-black">{{
                patientDemographics.name.charAt(0).toUpperCase() }}{{
                  patientDemographics.lastName.charAt(0).toUpperCase() }}</h5>
            </v-avatar>
          </div>
          <div class="text-onSurfaceVar">
            <h6 class="text-h6 font-weight-bold">{{ patientDemographics.name }} </h6>
            <span class="text-subtitle-2"> {{ age(patientDemographics.dob!) }} years, {{
              patientDemographics.gender
            }}</span>
          </div>
        </v-col>
        <v-col cols="3" class="d-flex justify-end">
          <v-divider vertical />
          <div v-if="!!patientProfileInformation.patientId"  class="text-right h-100 d-flex justify-center align-center text-primary">
            <patient-monitor-time-tracker  :is-activity-timer-paused="isActivityTimerPaused"
              :current-activity="currentActivity" :props-patient-id="patientProfileInformation.patientId"
              :inactivity-timeout="inactivityTimeout"
              :patient-name="`${patientProfileInformation.patientInfo?.firstName} ${patientProfileInformation.patientInfo?.lastName}`"
              tracker-mode='TOTAL' @request-patient-monitor-exit="handlePatientMonitorExitRequest" />
          </div>
          <v-divider vertical />
        </v-col>
        <v-col v-if="isReviewLoading" cols="3">
          <v-skeleton-loader type="table-heading" />
        </v-col>
        <v-col v-else ls="3" class="d-flex justify-center align-center">
          <div class="text-center mx-2">
            <v-chip color="tertiary" size="x-small" class="mx-1">
              <span class="text-caption font-weight-bold">
                Last data sync
              </span>
            </v-chip>
            <div>
              <span class="text-subtitle-2 text-tertiary">{{ lastSyncTime }}</span>
            </div>
            <v-tooltip activator="parent" location="bottom">
              {{ lastSyncTimeLabel }}
            </v-tooltip>
          </div>
          <div class="d-flex align-center mx-2">
            <v-btn @click="confirmDialog = true" variant="tonal" density="comfortable" elevation="3" rounded
              :disabled="isReviewedPatient" icon="mdi-check-bold" size="small" color="tertiary" />
            <v-tooltip activator="parent" location="bottom">
              Mark Data as Reviewed
            </v-tooltip>
          </div>


        </v-col>
        <v-col v-if="isActionLoading" :cols="!compactView ? '2' : '3'" class="">
          <v-skeleton-loader type="button@3" />
        </v-col>
        <v-col v-else :cols="!compactView ? '2' : '3'" class="d-flex justify-center align-center">
          <div class="d-flex align-center mx-4">
            <v-btn @click="dialog = true" variant="tonal" density="comfortable" rounded elevation="3"
              icon="mdi-timer-plus-outline" size="small" />
            <v-tooltip activator="parent" location="bottom">
              Add Time Log
            </v-tooltip>
          </div>
          <div class="d-flex align-center mx-4">
            <v-btn @click="noteTaskFormDialog = true" variant="tonal" density="comfortable" rounded elevation="3"
              icon="mdi-note-plus" size="small" />
            <v-tooltip activator="parent" location="bottom">
              Add Note
            </v-tooltip>
          </div>
          <div class="d-flex align-center mx-4">
            <v-btn @click="openPatientEditForm = true" v-if="hasAccess.viewPatientActions(viewOrgAdminRole)"
              variant="tonal" density="comfortable" rounded elevation="3" icon="mdi-pencil" size="small" />
            <v-tooltip activator="parent" location="bottom">
              Edit Patient </v-tooltip>
          </div>
          <div class="d-flex align-center mx-4">
            <v-btn @click="openSnapshotView = true" variant="tonal" density="comfortable" rounded elevation="3"
              icon="mdi-file-export" size="small" />
            <v-tooltip activator="parent" location="bottom">
              Snapshot Patient Information
            </v-tooltip>
          </div>
          <!-- <div class="d-flex align-center mx-4">
            <v-btn @click="noteTaskFormDialog = true" variant="tonal" density="comfortable" rounded elevation="3"
              icon="mdi-pencil" size="small" />
            <v-tooltip activator="parent" location="bottom">
              Edit {{ patientDemographics.name }}'s Profile
            </v-tooltip>
          </div> -->
        </v-col>

        <v-col v-if="!compactView" cols="1" class="d-flex justify-center align-center">
          <div class="d-flex align-center mx-4">
            <v-btn
              @click="!route.query.name ? router.push({ name: 'ListPatient' }) : router.push({ name: 'PatientConsents' })"
              variant="tonal" density="comfortable" rounded elevation="3" icon="mdi-close-thick" size="small" />
            <v-tooltip activator="parent" location="bottom">
              Exit
            </v-tooltip>
          </div>
        </v-col>

      </v-row>
    </v-container>
    <v-dialog persistent v-model="dialog" width="650">
      <v-card>
        <AddTimeLog :patient-profile="patientProfile" @add-time-log-cancelled="dialog = false"
          :activity-option="activityOption!" @create-time-log="createTimeLog">
        </AddTimeLog>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" persistent width="500px">
      <v-card min-height="200px">
        <v-card-title class="font-weight-bold bg-surface pl-5 ">Confirm</v-card-title>
        <v-checkbox v-model="isAcknowledged" class="shrink mr-2 ml-2" color="primary">
          <template v-slot:label>
            <div class="text-dark text-subtitle-1 font-weight-medium">
              I acknowledge that I have reviewed the new vitals carefully.
            </div>
          </template>
        </v-checkbox>
        <v-card-actions class="px-3 py-5">
          <v-row justify="end">
            <v-col sm="12" md="3" class="text-center">
              <v-btn color="primary" class="text-white" density="comfortable" @click="confirmDialog = false"
                variant="tonal" elevation="3" block rounded="false">
                Cancel
              </v-btn>
            </v-col>
            <v-col sm="12" md="3" class="text-center">
              <v-btn color="primary" class="text-white" variant="flat" type="submit" block density="comfortable"
                rounded="false" :disabled="!isAcknowledged" @click="patientReviewed">
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="noteTaskFormDialog" width="900">
      <note-add-form :user-handler="getPatientDataDependencies.getLoggedInUserInfo"
        :task-queue-loader="getPatientDataDependencies.listTaskQueue"
        :list-assignees="getPatientDataDependencies.listAssignees"
        :assign-task-handler="getPatientDataDependencies.assignTaskToNote" @save-note="saveNote"
        :patient-profile="patientProfile" @close="noteTaskFormDialog = false">
      </note-add-form>
    </v-dialog>
  </v-card>
  <v-snackbar color="success" v-model="showSuccessMessage" location="top right" class="mt-16">{{ successMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showSuccessMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-snackbar color="error" v-model="showErrorMessage" location="top right" class="mt-16">{{ errorMessage }}
    <template v-slot:actions>
      <v-icon class="ml-3" @click="showErrorMessage = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
  <v-dialog v-model="errorDialog" persistent width="500px">
    <v-card min-height="200px">
      <v-card-title class="font-weight-bold bg-surface">Error</v-card-title>
      <v-card-text class="text-h6">{{ errorMessage }}
      </v-card-text>
      <v-card-actions class="px-3 py-5">
        <v-row justify="end">
          <v-col sm="12" md="3" class="text-center">
            <v-btn color="error" class="text-white" size="small" rounded="false" @click="errorDialog = false"
              elevation="3" block variant="elevated">
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="openPatientEditForm" persistent>
    <edit-patient :patient-detail="patientDetail" :list-org-service-providers="listOrgServiceProviders"
      :is-patient-profile="true" @patient-edited="closePatientEditForm" :profile-patient-id="patientId"></edit-patient>
  </v-dialog>

  <v-dialog width="1300"  v-model="openSnapshotView" persistent>
    <PatientSnapshotInfo @close="openSnapshotView = false" :patient-id="patientProfile.patientId"
      :snapshot-patient-info="getPatientDataDependencies.snapshotPatientInfo" @close-and-throw-error="snapshotPopupError"
      :patient-vital-config-loader="getPatientDataDependencies.getPatientVitalsThresholdConfig" />
  </v-dialog>

</template>
<script setup lang="ts">
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { computed, onMounted, PropType, ref, watch } from 'vue';
import { age } from '@/helpers/date.helpers';
import PatientMonitorTimeTracker from '../data-components/time-tracking/PatientMonitorTimeTracker.vue';
import { IPatientInfo } from '../../../interfaces/patient.interface';
import { PatientMonitorInfoEmits } from '@/types/patient-monitor.types';
import { ITaskActionEvent, } from '@/interfaces/task.interface';
import { patientActiveStatusEventBusKey, patientDetailsEventBusKey } from '@/events/bus-keys/patient-active-event.bus-keys';
import { useEventBus, useTimeAgo } from '@vueuse/core';
import { IPatientData } from '@/interfaces/Service.interface';
import { attempt, isEqual } from 'lodash';
import { Gender, GenderLabel } from '@/enums/genderType';
import AddTimeLog from '../../patient/data-components/actions/AddTimeLog.vue';
import { IActivityInput, IAddTimeLog, ITimeLog } from '@/interfaces/timelog.interface';
import { patientManualTimetrackerBusKey } from '@/events/bus-keys/time-tracking-events.bus-keys';
import { PatientProgramVitalsFilter } from '@/interfaces/patientInsights.interface';
import moment from 'moment';
import { patientReviewedEventBusKey, patientVitalsReviewEventBusKey } from '@/events/bus-keys/patient-reviewd-event.bus-keys';
import NoteAddForm from '../../views/note/NoteAddForm.vue';
import { INotes, ListNotes, NoteInputType, } from '@/interfaces/notes.interface';
import { formatTimestamp } from '@/composables/FormUtility';
import { notesListEventBusKey, noteTaskListEventBusKey } from '@/events/bus-keys/note-task-events.bus-keys';
import { useQueryClient } from '@tanstack/vue-query';
import { Program } from '@/enums/patient-program.enum';
import { Vitals } from '@/enums/patientInsights.enum';
import router from '@/router';
import { patientCreated } from '@/services/common.service';
import { ShowSnackbar } from '@/enums/sanckbar-show.enum';
import hasAccess, {
  viewOrgAdminRole
} from "../../../composables/roleAccess";
import { useRoute } from 'vue-router';
import EditPatient from '../../EditPatient.vue';
import { IOrgServiceProvider } from '@/interfaces/IOrganization';
import { noteTimeLogEventBusKey } from '@/events/bus-keys/notes-timelog-event.bus-keys';
import { patientProgramInsightsEventBusKey } from '../../../events/bus-keys/patient-program-insights-event.bus-key';
import PatientSnapshotInfo from '../snapshot/PatientSnapshotInfo.vue';


const props = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true
  },
  currentActivity: {
    type: Object as PropType<ITaskActionEvent>,
    required: false,
  },
  isActivityTimerPaused: {
    type: Boolean,
    required: false,
  },
  getPatientDataDependencies: {
    type: Object as PropType<IPatientData>,
    required: true,
  },
  activityOption: {
    type: Object as PropType<IActivityInput>,
    required: false,
  },
  addTimeLog: {
    type: Function as PropType<(input: IAddTimeLog) => Promise<ITimeLog>>,
    required: false,
  },
  addNoteHandler: {
    type: Function as PropType<(createNoteInput: NoteInputType) => Promise<INotes>>,
    required: true,
  },
  isTaskBoardTaskView: {
    type: Boolean,
    default: false,
    required: false,
  },
  isDashboardView: {
    type: Boolean,
    default: false,
    required: false,
  },
  markInsightAsReviewed: {
    type: Function as PropType<(
      patientId: string,
      program: Program,
      vital: Vitals,
      isAcknowledged: boolean,
    ) => Promise<boolean>>,
    required: false
  },
  compactView: {
    type: Boolean,
    required: false,
    default: false
  },

  patientDetail: {
    type: Function as PropType<(patientId: string) => Promise<IPatientInfo>>,
    required: true,
  },

  listOrgServiceProviders: {
    type: Function as PropType<(orgId: string) => Promise<IOrgServiceProvider[]>>,
    required: true,
  },
});

const { patientProfile, getPatientDataDependencies, addTimeLog, addNoteHandler, markInsightAsReviewed } = props;

const patientProfileInformation = ref({} as IPatientProfileProps);

const inactivityTimeout = Number(import.meta.env.VITE_INACTIVITY_TIMEOUT || 10000);
const patientInfoEmit = defineEmits<PatientMonitorInfoEmits>();
const patientStatusChangeEventBus = useEventBus(patientActiveStatusEventBusKey);
const patientManualTimetrackerBus = useEventBus(patientManualTimetrackerBusKey);
const noteTaskActionEventBus = useEventBus(noteTaskListEventBusKey);
const notesListEventBus = useEventBus(notesListEventBusKey);
const notesTimeLogEventBus = useEventBus(noteTimeLogEventBusKey);
const patientProgramInsightsEvent = useEventBus(patientProgramInsightsEventBusKey);

const patientDemographics = ref({
  name: '',
  lastName: '',
  dob: '',
  gender: '',
  deceased: false,
  isActive: false,
});

const avatarColor = ref();
const isActive = ref<boolean>(true)
const isDeceased = ref<boolean>(false)
const activeColor = 'success';
const deceasedColor = 'error';
const inactiveColor = 'warning';
const patientId = ref('');
const loading = ref(false);
const dialog = ref(false);
const showSuccessMessage = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref('')
const successMessage = ref('');
const noteTaskFormDialog = ref(false);
const queryClient = useQueryClient();
const errorDialog = ref(false);
const route = useRoute();
const openPatientEditForm = ref(false);

const isActionLoading = ref(false);
const isReviewLoading = ref(false);
const openSnapshotView = ref(false);

const isReviewedPatient = ref(true);
const lastSyncTime = ref('Never');
const lastSyncTimeLabel = ref('Last sync information unavailable');
const confirmDialog = ref(false);
const isAcknowledged = ref(false);
const patientVitalsReviewEventBus = useEventBus(patientVitalsReviewEventBusKey);
const patientReviewChangeEventBus = useEventBus(patientReviewedEventBusKey);

const statusEvent = {
  'success': () => {
    isDeceased.value = false;
    isActive.value = true;
  },
  'warning': () => {
    isDeceased.value = false;
    isActive.value = false;
  },
  'error': (e: string) => {
    isDeceased.value = true;
    isActive.value = false;
  }
};

onMounted(() => {
  patientStatusChangeEventBus.on((e: string) => {
    if (!isEqual(e, 'status-updated-from-header-menu')) {
      const action = statusEvent[e as keyof typeof statusEvent];
      action(e);
    }
  });
  if (patientCreated.value === ShowSnackbar.PATIENT_EDITED) {
    successMessage.value = 'Patient record edited successfully';
    showSuccessMessage.value = true;
    patientCreated.value = '';
  }
  useEventBus(patientDetailsEventBusKey).on((patientInfo) => {
    patientDemographics.value = {
      ...patientInfo,
      name: `${patientInfo.firstName} ${patientInfo.lastName}`,
      gender: GenderLabel[patientInfo.gender as Gender] || patientInfo.gender,
      deceased: patientInfo.isDeceased,
    };
  })

  patientProgramInsightsEvent.on(() => {
    checkPatientIsReviewed();
  })
});

const populatePatientDemographics = (patientInfo: IPatientInfo) => {
  patientId.value = patientInfo.patientId;
  patientDemographics.value = {
    name: `${patientInfo.firstName} ${patientInfo.lastName}`,
    dob: patientInfo.dob,
    lastName: patientInfo.lastName,
    gender: GenderLabel[patientInfo.gender as Gender] || patientInfo.gender,
    deceased: false,
    isActive: true,
  };
  isDeceased.value = patientInfo.isDeceased;
  isActive.value = patientInfo.isActive;
};

const loadPatientData = async (patientId: string) => {
  const patientInfo = patientProfileInformation.value.patientInfo;
  const patientInfoLoader = patientProfileInformation.value.patientInfoLoader;
  if (patientInfo) {
    populatePatientDemographics(patientInfo);
  } else if (patientInfoLoader) {
    const patientInfo = await patientInfoLoader([patientId]);
    populatePatientDemographics(patientInfo);
  }
};

watch(() => props.patientProfile,
  async (newValue) => {
    if(newValue)
    patientProfileInformation.value = newValue;
    await loadPatientData(newValue.patientId);
    checkPatientIsReviewed();
  },
  { deep: true, immediate: true }
);

const handlePatientMonitorExitRequest = () => {
  patientInfoEmit('requestPatientMonitorExit');
};


const computedAvatarColor = computed(() => {
  const deceased = isDeceased.value;
  const active = isActive.value;

  return avatarColor.value || (active && activeColor) || (deceased && deceasedColor) || inactiveColor;
});


const createTimeLog = async (timeLogInput: IAddTimeLog) => {
  try {
    isActionLoading.value = true;
    dialog.value = false;
    await addTimeLog!(timeLogInput);
    isActionLoading.value = false;
    successMessage.value = "Time log added successfully"
    showSuccessMessage.value = true;
    patientManualTimetrackerBus.emit('patient-manual-time-tracker-bus')
    notesTimeLogEventBus.emit('note-timelog-list-event-bus')
  } catch (error) {
    isActionLoading.value = false;
    const { message } = error as Error;
    const parsedMessage = attempt(JSON.parse, message);
    if (parsedMessage?.code === 'OVER_LAPPING_TIME_LOG') {
      errorDialog.value = true;
      errorMessage.value = parsedMessage.message;
    } else {
      showErrorMessage.value = true;
      errorMessage.value = message;
    }
  }
}

const triggerMarkInsightAsReviewed = async () => {
  if (markInsightAsReviewed && isAcknowledged.value) {
    isReviewLoading.value = true;
    await markInsightAsReviewed(patientId.value, Program.RPM, Vitals.BP, isAcknowledged.value);
    checkPatientIsReviewed();
    isReviewLoading.value = false;
    patientReviewChangeEventBus.emit('isReviewed');
    confirmDialog.value = false;
    queryClient.invalidateQueries({ queryKey: ['patientInsightsOverview'] })
  }
}

const checkPatientIsReviewed = async () => {
  const patientId = patientProfile.patientId;
  const filterData = { patientId } as PatientProgramVitalsFilter;
  isReviewLoading.value = true;
  const response = await getPatientDataDependencies.getPatientReviewedStatus(0, 0, filterData).finally(() => {
    isReviewLoading.value = false;
  });
  if (response.results && response.results.length > 0) {
    const isReviewedValue = response.results[0];
    const lastSync = isReviewedValue?.lastSync;
    lastSyncTimeLabel.value = lastSync ? ` Last data synced at ${moment(lastSync).format('dddd, MMMM D, YYYY, h:mm:ss A')}` : `Last sync information unavailable`;
    lastSyncTime.value = lastSync ? useTimeAgo(lastSync).value.replace('"', '') : 'Never';
    isReviewedPatient.value = isReviewedValue.isReviewed as boolean;
  }
};

const patientReviewed = () => {
  confirmDialog.value = false;
  triggerMarkInsightAsReviewed();
  patientVitalsReviewEventBus.emit('reviewAcknowledged')
}

const closePatientEditForm = () => {
  openPatientEditForm.value = false;
  if (patientCreated.value === ShowSnackbar.PATIENT_EDITED) {
    successMessage.value = 'Patient record edited successfully';
    showSuccessMessage.value = true;
    patientCreated.value = '';
  }
}

const saveNote = async (input: NoteInputType) => {
  try {
    isActionLoading.value = true;
    const createdNote = await addNoteHandler(input);
    noteTaskFormDialog.value = false;
    isActionLoading.value = false;
    successMessage.value = "Notes saved successfully"
    showSuccessMessage.value = true;
    const newNote: ListNotes = {
      id: createdNote.mpi,
      author: `${createdNote.note.author.firstName} ${createdNote.note.author.lastName}`,
      createdAt: formatTimestamp(createdNote.note.createdAt),
      editAt: formatTimestamp(createdNote.note.lastModifiedAt),
      noteContent: createdNote.note.noteContent.message,
      actionPop: false,
    };
    notesListEventBus.emit(newNote);
    noteTaskActionEventBus.emit('note-task-list-event-bus');
    if (input.task) {
      queryClient.invalidateQueries({ queryKey: ['taskBoard'] })
    }
  }
  catch (error) {
    isActionLoading.value = false;
    noteTaskFormDialog.value = false;
    errorMessage.value = 'Error while saving note, please try again later';
    showErrorMessage.value = true;
  }
};

const snapshotPopupError = (error: string) => {
  openSnapshotView.value = false;
  errorMessage.value = error;
  showErrorMessage.value = true;
}
</script>

<script lang="ts">

export default {
  name: 'PatientInfo',
}
</script>

<template>
  <v-card variant="flat" color="surfContainerHigh" elevation="0">
    <div class="d-flex flex-row">
      <v-tabs v-model="dataTab" direction="vertical" color="primary" class="bg-surface text-primary " density="compact">
        <v-tab :value="PATIENT_DATA_CATEGORIES.DETAILS">
          <v-icon start>
            mdi-account-details-outline
          </v-icon>
          <span class="text-subtitle-2 font-weight-bold">Details</span>
        </v-tab>
        <v-tab :value="PATIENT_DATA_CATEGORIES.VITALS">
          <v-icon start>
            mdi-pulse
          </v-icon>
          <span class="text-subtitle-2 font-weight-bold">Vitals</span>
        </v-tab>
        <v-tab :value="PATIENT_DATA_CATEGORIES.COMPLIANCE">
          <v-icon start>
            mdi-timer-sand
          </v-icon>
          <span class="text-subtitle-2 font-weight-bold">Adherence</span>
        </v-tab>
        <v-tab :value="PATIENT_DATA_CATEGORIES.ACTIONS">
          <v-icon start>
            mdi-gesture-tap-button
          </v-icon>
          <span class="text-subtitle-2 font-weight-bold">Actions</span>
        </v-tab>

        <v-tab :value="PATIENT_DATA_CATEGORIES.INTEGRATIONS">
          <v-icon start>
            mdi-relation-one-to-many
          </v-icon>
          <span class="text-subtitle-2 font-weight-bold">Devices</span>
        </v-tab>

        <v-tab :value="PATIENT_DATA_CATEGORIES.CRITERIA">
          <v-icon start>
            mdi-account-wrench-outline
          </v-icon>
          <span class="text-subtitle-2 font-weight-bold">Thresholds</span>
        </v-tab>
        <v-tab :value="PATIENT_DATA_CATEGORIES.SUBSCRIPTIONS">
          <v-icon start>
            mdi-medical-bag
          </v-icon>
          <span class="text-subtitle-2 font-weight-bold">Subscriptions</span>
        </v-tab>
        <v-tab :value="PATIENT_DATA_CATEGORIES.UPLOADS">
          <v-icon start>
            mdi-file-upload-outline
          </v-icon>
          <span class="text-subtitle-2 font-weight-bold">Documents</span>
        </v-tab>
      </v-tabs>
      <v-window v-model="dataTab" class="w-100 window-container" :touch="false">
        <v-window-item :value="PATIENT_DATA_CATEGORIES.VITALS">
          <patient-vitals :get-patient-data-dependencies="getPatientDataDependencies"
            :patient-profile="patientProfile" />
        </v-window-item>
        <v-window-item :value="PATIENT_DATA_CATEGORIES.DETAILS">
          <patient-details :get-details-dependencies="patientDetailsDependencies" :patient-profile="patientProfile"
            :compact-view="compactView" :patient-sources-loader="patientIntegrationsDependencies.listPatientSources" />
        </v-window-item>
        <v-window-item :value="PATIENT_DATA_CATEGORIES.COMPLIANCE">
          <patient-compliance :compact-view="compactView"
            :get-patient-program-cycle-dependencies="patientCompianceDependencies" :patient-profile="patientProfile" />
        </v-window-item>
        <v-window-item :value="PATIENT_DATA_CATEGORIES.ACTIONS">
          <patient-actions :get-action-dependencies="patientActionDependencies" :patient-profile="patientProfile"
            :get-task-dependencies="getPatientDataDependencies"
            :get-task-details-dependencies="getPatientDataDependencies"
            :is-task-board-task-view="isTaskBoardTaskView" />
        </v-window-item>

        <v-window-item :value="PATIENT_DATA_CATEGORIES.INTEGRATIONS">
          <patient-integrations :get-integration-dependencies="patientIntegrationsDependencies"
            :get-details-dependencies="patientDetailsDependencies" :patient-profile="patientProfile"
            :get-patient-program-consent-dependencies="patientProgramConsentDependencies" />
        </v-window-item>

        <v-window-item :value="PATIENT_DATA_CATEGORIES.CRITERIA">
          <vital-selection-comp :patient-id="patientProfile.patientId"
            :patient-vital-config-loader="getPatientDataDependencies.getPatientVitalsThresholdConfig"
            :save-patient-vital-config-handler="getPatientDataDependencies.savePatientVitalsThresholdConfig"
            :vital-criteria-compact-view="vitalCriteriaCompactView" />
        </v-window-item>

        <v-window-item :value="PATIENT_DATA_CATEGORIES.SUBSCRIPTIONS">
          <patient-program :patient-profile="patientProfile"
            :get-patient-program-consent-dependencies="patientProgramConsentDependencies"></patient-program>
        </v-window-item>

        <v-window-item :value="PATIENT_DATA_CATEGORIES.UPLOADS">
          <file-upload-handler :patient-document-lists="getPatientDataDependencies.patientDocumentLists"
            :create-patient-document="getPatientDataDependencies.createPatientDocument" :download-file="getPatientDataDependencies.downloadUploadedFile"
            :patient-id="patientProfile.patientId" :delete-patient-uploaded-document="getPatientDataDependencies.deletePatientUploadedDocument"
            :get-file-upload-token="getPatientDataDependencies.getFileUploadToken"></file-upload-handler>
        </v-window-item>
      </v-window>
    </div>
  </v-card>
</template>
<script setup lang="ts">
import { PropType, ref } from 'vue';
import PatientVitals from '../data-components/vitals/PatientVitals.vue';
import PatientDetails from '../data-components/details/PatientDetails.vue';
import PatientActions from '../data-components/actions/PatientActions.vue';
import PatientCompliance from '../data-components/compliance/PatientCompliance.vue';
import PatientIntegrations from '../data-components/integrations/PatientIntegrations.vue';
import { PATIENT_DATA_CATEGORIES } from '../../../enums/patient.enum';
import { IPatientData } from '@/interfaces/Service.interface';
import { pick } from 'lodash';
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import VitalSelectionComp from '../../vital/VitalSelectionComp.vue'
import PatientProgram from '../data-components/subscriptions/PatientProgram.vue';
import FileUploadHandler from '@/components/fileUploader/FileUploadHandler.vue';

const { getPatientDataDependencies, patientProfile } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true
  },
  getPatientDataDependencies: {
    type: Object as PropType<IPatientData>,
    required: true,
  },
  compactView: {
    type: Boolean,
    required: false,
    default: false
  },
  isTaskBoardTaskView: {
    type: Boolean,
    default: false,
    required: false,
  },
  vitalCriteriaCompactView: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const patientActionDependencies = pick(getPatientDataDependencies, [
  'createNote',
  'getNotes',
  'listTaskQueue',
  'assignTaskToNote',
  'listAssignees',
  'getLoggedInUserInfo',
  'getServiceProviders',
  'listSourceDefinitions',
  'getPatientSourceExternalIds',
  'getOrgDevicesFromQardio',
  'listPatientSource',
  'savePatientSource',
  'getPatientByPatientId',
  'listPatientTask',
  "getPatientTimeLogs",
  "deleteTimeLog",
  'listActivities',
  'createAddTimeLog',
  'getPatientReviewedStatus',
  'listTaskActivities',
  'createComment',
  'getOrgTaskStatusStates'
])

const patientIntegrationsDependencies = pick(getPatientDataDependencies, [
  'getServiceProviders',
  'listSourceDefinitions',
  'getPatientSourceExternalIds',
  'getOrgDevicesFromQardio',
  'savePatientSource',
  'listPatientSource',
  'getLoggedInUserInfo',
  'listPatientSources',
  'listSourcesInput',
  'assignInventoryItem',
  'inventoryItemInput',
  'createShipInventoryItem',
  'getAllOrgServiceProviders',
  'getPatientByPatientId',
])

const patientDetailsDependencies = pick(getPatientDataDependencies, [
  'getPatientByPatientId',
  'getPatientProviders',
  'updatePatient',
  'getPatientBillingConfig',
  'savePatientBillingConfig',
  'savePatientEvent',
  'getPatientLinkedUsers',
  'updateUserPatientLinkStatus'
])

const patientCompianceDependencies = pick(getPatientDataDependencies, [
  'getPatientProgramCycles'
])

const patientProgramConsentDependencies = pick(getPatientDataDependencies, [
  'patientProgramConsents',
  'getProgramEConsentTemplate',
  'getProgramEConsentUploadUrl',
  'getProgramEConsentDownloadUrl',
  'signPatientProgramConsent',
  'subscribePrograms',
  'unSubscribeProgram',
  'getAllPrograms'
])


const dataTab = ref(PATIENT_DATA_CATEGORIES.DETAILS)
</script>

<style scoped>
.window-container {
  min-height: calc(100vh - 174px) !important;
  background-color: rgba(var(--v-theme-surface)) !important;
}
</style>
